import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

const ServiceShortLink = () => {

    const {serviceNumber} = useParams();

    useEffect(() => {
        let url = `/services/${+serviceNumber + 30001000}`;
        if ( +serviceNumber === 30001000 )
            url = `/services/${+serviceNumber}`;
        window.location.replace(url)
    }, []);

    return (
        <></>
    )
};

export default ServiceShortLink;
