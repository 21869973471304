import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const notification = (args) => {
    let { text, type } = args;
    Swal.fire({
        text,
        icon: type,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });
};

export const confirmation = (args) => {
    const MySwal = withReactContent(Swal);
    return new Promise((resolve, reject) => {
        MySwal.fire({
            ...args,
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
        }).then((result) => {
            if (result.isConfirmed) {
                resolve(true); // User confirmed
            } else {
                resolve(false); // User canceled
            }
        });
    });
};
