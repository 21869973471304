import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

const AssetShortLink = () => {

    const {assetNumber} = useParams();

    useEffect(() => {
        console.log({assetNumber})
        let url = `/assets/${+assetNumber + 100000000}`;
        // console.log({url})
        window.location.replace(url)
    }, []);

    return (
        <></>
    )
};

export default AssetShortLink;
