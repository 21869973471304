import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

window.redirectByDomain = () => window.location.assign(`https://rentables.app${window.location.pathname}`);

if (['rentables.app'].includes(window.location.host)) {
  // window.redirectByDomain();
}

if (window.location.host.indexOf('local') < 0 && window.location.host.indexOf('rentables.app') > -1 && window.location.protocol.indexOf('https') < 0) {
  window.redirectByDomain();
}

window.setConfig = async () => {
  let configHost = process.env.REACT_APP_API_CONFIG_HOST;
  const response = await fetch(configHost).then(response => response.json());
  localStorage.setItem('HOST_CONFIG', JSON.stringify(response));
};

window.getConfig = key => {
  let configData = {};
  let configString = localStorage.getItem('HOST_CONFIG');
  if (configString) configData = JSON.parse(configString);
  configData = { ...configData, ...process.env };
  return configData[key] || configData[`RENTABLES_API_${key}`] || configData[`RENTABLES_API_MVP_${key}`] || configData[`REACT_APP_API_${key}`] || configData[key.replace('REACT_APP_API_', '')] || null;
};

window.getServiceEndpoint = service => {
  let envQualifier = 'NON_PROD';
  if (window.location.host.indexOf('local') === 0) envQualifier = 'LOCAL';
  else if (window.location.host.indexOf('beta.rentables.app') === 0) envQualifier = 'BETA';
  else if (window.location.host.indexOf('qa.rentables.app') === 0) envQualifier = 'QA';
  else if (window.location.host.indexOf('dev.rentables.app') === 0) envQualifier = 'dev';
  else if (window.location.host.indexOf('rent-uat.c5office.com') === 0) envQualifier = 'UAT';
  else if (window.location.host.indexOf('rentables.app') === 0) envQualifier = 'PROD';
  else if (window.location.host.indexOf('.rentables.app') > 0) envQualifier = 'PROD';

  let finalConfigKey = `${(service || 'RENTABLES_API').toUpperCase()}_HOST_${envQualifier}`;
  console.log(finalConfigKey);
  return window.getConfig(finalConfigKey);
};

window.getAxiosConfig = () => {
  let headers = { access_token: localStorage.getItem('access_token'), 'Content-Type': 'application/json' };
  return { headers };
};

window.friendlyCase = function (string) {
  if ( !string ) return '';
  let result = string.replace(/([A-Z])/g, " $1");
  let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  let knownWords = {
    "Trn": 'TRN',
    "Date Of Birth": 'Date of Birth',
  }
  for (let w in knownWords) {
    if (finalResult === w) {
      return knownWords[w];
    }
  }
  return finalResult;
};

String.prototype.toFriendlyCase = function () {
  return window.friendlyCase(this);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
