const PrivayPolicy = () => {
    return (
        <div className="py-10 px-5 md:py-20 md:px-20">

            <div class="text-gray-900 space-y-6">
                <h1 class="text-3xl font-bold">Privacy Policy</h1>

                <p class="text-lg">At Rentables, we are committed to protecting your privacy as a guest, client or an owner of assets. Our Privacy Policy outlines how we collect, use, and safeguard your personal information.</p>

                <h2 class="text-2xl font-semibold">Information Collection</h2>

                <p class="text-lg">We may collect personal and payment information when you create an account, make a booking as a client, or list your assets as an owner, or communicate with us.</p>

                <h2 class="text-2xl font-semibold">Use of Information</h2>

                <p class="text-lg"><strong>For Clients:</strong> We use your information to facilitate your rentals, communicate with you, and provide customer support.</p>

                <p class="text-lg"><strong>For Owners:</strong> We use your information to audit asset listings, and facilitate rental agreements with clients, communicate with you, and provide customer support.</p>

                <h2 class="text-2xl font-semibold">Security</h2>

                <p class="text-lg">We implement security measures to protect your information from unauthorised access and misuse, ensuring the safety of both clients and owners.</p>

                <h2 class="text-2xl font-semibold">Cookies</h2>

                <p class="text-lg">Our website may use cookies to enhance your browsing experience. You can manage cookie preferences in your browser settings.</p>

                <h2 class="text-2xl font-semibold">Payment Information</h2>

                <p class="text-lg">Our online payments are processed securely by Stripe. We do not capture any card payment information, even for transmission to Stripe. Stripe's service is directly integrated so that from your device to their secured platform, your payment card information is privately transmitted. We then interact with references of such payment card info, against which we charge and capture funds, or void transactions where necessary.</p>

                <h2 class="text-2xl font-semibold">Third-Party Links</h2>

                <p class="text-lg">Our website may contain links to third-party websites. We are not responsible for their privacy practices, and this applies to all types of users.</p>

                <h2 class="text-2xl font-semibold">Updates to Privacy Policy</h2>

                <p class="text-lg">We may update our Privacy Policy to reflect changes in our practices, ensuring transparency for all parties. Updates will be posted on our website.</p>

                <p class="text-lg">By using our website and services, you consent to the practices outlined in this Privacy Policy.</p>

                <p class="text-lg">For any questions or concerns regarding your privacy, please contact us at <a href="mailto:service@rentables.app" class="text-blue-600 underline">service@rentables.app</a>.</p>

                <p class="text-lg italic">Last updated: October 31, 2023</p>
            </div>
        </div>
    )
};

export default PrivayPolicy;