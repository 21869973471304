import React, { useEffect, useState } from 'react';

const Support = () => {

    useEffect(() => {
        document.title = 'Support - Rentables App';
    }, []);

    const faqs = [
        {
            "question": "How can I list my asset if the asset owner's portal is not yet live?",
            "answer": `While the asset owner's portal is under development, we are listing assets manually. Use the following link to add the details of your asset and we will publish it for you. Link: <a href="https://forms.gle/ot8QSgoJCcT1Awwm9" target="_blank" class="text-blue-600">https://forms.gle/ot8QSgoJCcT1Awwm9</a>`
        },
        {
            "question": "What happens after I list my asset?",
            "answer": "Once your asset is listed, it will be available for rental. You will receive a link that you can share and promote your new rentable. Clients can book the rentable and you will receive notifications of bookings."
        },
        {
            "question": "How does the payment process work?",
            "answer": "We collect all payments from clients upfront. After the client confirms receipt of the asset within 24 hours, payment is released to the asset owner."
        },
        {
            "question": "What if the client does not confirm receipt within 24 hours?",
            "answer": "If the client fails to confirm receipt within 24 hours, we will automatically assume they have received the asset, and the payment will be released."
        },
        {
            "question": "When will the asset owners' portal be available?",
            "answer": "The asset owners' portal is currently under development and will be available in a few weeks. You will be able to log in, manage assets, and view statistics and payments."
        },
        {
            "question": "What are my responsibilities as an asset owner?",
            "answer": "As an asset owner, you are responsible for maintaining the condition of your asset, ensuring its availability, and accepting or declining rental requests in a timely manner."
        },
        {
            "question": "Is there a commission fee?",
            "answer": "Yes, Rentables operates on a commission-based model. The amount is negotiable."
        },
        {
            "question": "How do I get support?",
            "answer": "For any queries or support, you can reach out to our team via the Live Support channel on this page."
        },
        {
            "question": "How can Rentables be contacted?",
            "answer": "Contact us via Live Support first, but you can also send us an email at rentables@c5office.com, or call/SMS/WhatsApp us at +18764941728."
        }
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    return (
        <div className={"px-10 md:p-20"}>
            <h1 className={`text-3xl text-center font-bold`}>
                Support
            </h1>
            <div className='mt-5 md:w-2/3 mx-auto'>
                <div className='text-xl text-gray-600 mt-5 mb-5 text-center'>
                    Welcome to our initial support site. For now, while we're still building, we have a few frequently answered questions below, and you can also reach out to us via Live Support, using the icon to the lower right hand corner of the screen.
                </div>

                <hr />

                <div className='mb-5'>

                    <div className="py-4">
                        <h2 className="text-3xl font-light mb-4 text-center">Frequently Answered Questions</h2>
                        <div>
                            {faqs.map((faq, index) => (
                                <div key={index} className="border-b-2 last:border-b-0">
                                    <button
                                        className="w-full text-left py-4"
                                        onClick={() => setActiveIndex(index === activeIndex ? null : index)}
                                    >
                                        <div className="flex justify-between items-center">
                                            <span className={index === activeIndex ? 'font-bold' : ''}>{faq.question}</span>
                                            <span>{activeIndex === index ? '-' : '+'}</span>
                                        </div>
                                    </button>
                                    {activeIndex === index && (
                                        <div className="pb-4">
                                            <p className='text-gray-700' dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};

export default Support;