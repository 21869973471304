import React, { useState, useEffect, useRef } from 'react';
import { post, get } from '../utils/http';
import { notification } from '../utils/notification';

const getQueryStringValue = (key) => {
    return new URLSearchParams(window.location.search).get(key) || '';
}

const Roadmap = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [features, setFeatures] = useState([]);
    const [statusFilter, setStatusFilter] = useState('');
    const [token, setToken] = useState(getQueryStringValue('token').length > 16 ? getQueryStringValue('token') : '');
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [details, setDetails] = useState('');
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [featureCount, setFeatureCount] = useState(0);

    const [isHeadless, setIsHeadless] = useState(true);
    const [isHomeScreen, setIsHomeScreen] = useState(true);

    useEffect(() => {
        setIsHeadless(getQueryStringValue('headless') === "true");
        setIsHomeScreen((window.location.pathname.split('?')[0]).split('#')[0] === '/');
    }, []);

    const observer = useRef();
    const lastElementRef = useRef((node) => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                setPage((prevPage) => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    });

    const getFeatures = async (args) => {
        if (!hasMore) {
            return notification({ text: "There are no or matching features.", type: "info" });
        }
        setLoading(true);
        const endpoint = `${window.getServiceEndpoint('')}/public/roadmap?token=${token}`;
        const body = {
            statusFilter: args.filter || statusFilter,
            searchTerm: args.search || searchTerm,
            page,
        };
        const response = await post(endpoint, body);
        const results = response.data;
        const mergeUnique = (prevData, results) => {
            const merged = [...prevData];

            for (const result of results) {
                const existingItemIndex = merged.findIndex(item => item._id === result._id);

                if (existingItemIndex === -1) {
                    merged.push(result);
                } else {
                }
            }

            return merged;
        };

        setFeatures(prevData => mergeUnique(prevData, results));
        setLoading(false);

        if (results.length < 10) {
            setHasMore(false);
        }
    };

    useEffect(() => {
        getFeatureCount();
    }, []);

    const getFeatureCount = async () => {
        const endpoint = `${window.getServiceEndpoint('')}/public/roadmap/count?token=${token}`;
        const response = await get(endpoint);
        const results = response.data;
        setFeatureCount(results.count);
    };

    useEffect(() => {
        if (hasMore) {
            getFeatures({});
        }
    }, [page]);

    const updateStatusFilter = filter => {
        setStatusFilter(filter);
        setFeatures([]);
        setPage(1);
        getFeatures({ filter });
    }

    const updateSearchTerm = search => {
        setSearchTerm(search);
        setFeatures([]);
        setPage(1);
        getFeatures({ search });
    }

    const requestFeature = async (featureId) => {
        if (!token) {
            notification({ text: "Log in first.", type: "error" });
            return;
        }
        setLoading(true);
        const response = await post(`${window.getServiceEndpoint('')}/public/roadmap/request-feature?token=${token}`, { featureId });

        if (response.status === 200) {
            setFeatures(prevFeatures => prevFeatures.map(feature => {
                if (feature._id === featureId) {
                    return { ...feature, isIncluded: true, queueSize: feature.queueSize + 1 };
                }
                return feature;
            }));
        }
        setLoading(false);
    };

    const submitFeatureRequest = async () => {
        setLoading(true);
        const payload = { title, details, type: 'feature' };
        try {
            await post(`${window.getServiceEndpoint('')}/public/user-report?token=${token}`, payload);
            setTitle('');
            setDetails('');
            setShowModal(false);
        } catch (error) {
            console.error('Error submitting feature request:', error);
        } finally {
            setLoading(false);
        }
    };

    const [expandedFeatureId, setExpandedFeatureId] = useState(null); // New state for tracking expanded feature

    const toggleFeatureExpansion = (id) => {
        if (expandedFeatureId === id) {
            setExpandedFeatureId(null); // Collapse if already expanded
        } else {
            setExpandedFeatureId(id); // Expand the selected feature
        }
    };

    return (
        <div>
            <div className={`fixed bottom-0 left-0 right-0 flex justify-center opacity-90`}>
                <button
                    className="bg-black p-2 w-full text-white"
                    onClick={() => token ? setShowModal(true) : notification({ text: "Log in first.", type: "error" })}
                >
                    💡 I have an idea!
                </button>
            </div>

            {
                showModal && (
                    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white rounded-lg p-8 w-96">
                            <h2 className="text-lg font-bold mb-4">New Feature Request</h2>
                            <p className="mb-4 text-sm text-gray-600">
                                We value your feedback. If what you're reporting is a bug, please submit it in the app. Look for a bug (an actual insect) somewhere to the lower right hand side of the screen and <span className='line-through'>squash</span> tap it (not too hard).
                            </p>
                            <input
                                type="text"
                                placeholder="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="p-2 px-5 mb-4 border rounded-full w-full"
                            />
                            <textarea
                                rows="4"
                                placeholder="Details"
                                value={details}
                                onChange={(e) => setDetails(e.target.value)}
                                className="p-5 mb-4 border rounded-lg w-full"
                            ></textarea>
                            <button onClick={submitFeatureRequest} className="bg-gray-800 text-white p-2 px-5 rounded-full">
                                Request Feature
                            </button>
                            <button onClick={() => setShowModal(false)} className="ml-4 text-gray-500">
                                Cancel
                            </button>
                        </div>
                    </div>
                )
            }
            <div className={`bg-white p-6 ${isHeadless ? 'pt-0' : 'md:pt-20'}  md:w-3/4 md:mx-auto`}>

                <h1 className='text-2xl text-indigo-900 mb-5 text-center'>
                    We Build Publicly.
                </h1>


                <div className='text-sm text-center mb-5 border-t-1 border-b-1 border-blue-500'>
                    We value user feedback over everything else.
                </div>
                <input
                    type="text"
                    placeholder={`Search ${featureCount > 0 ? `${featureCount} ` : ''}features`}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            updateSearchTerm(e.target.value);
                        }
                    }}
                    className="p-2 px-4 mb-4 border rounded-full w-full"
                />

                <div className="flex justify-center items-center space-x-4 mb-4">
                    <button onClick={() => updateStatusFilter('new')} className={`${'new' !== statusFilter ? 'bg-gray-800' : 'bg-indigo-800'} text-xs text-white p-2 px-3 rounded-full`}>&nbsp;New&nbsp;</button>
                    <button onClick={() => updateStatusFilter('planned')} className={`${'planned' !== statusFilter ? 'bg-gray-800' : 'bg-indigo-800'} text-xs text-white p-2 rounded-full`}>Planned</button>
                    <button onClick={() => updateStatusFilter('inProgress')} className={`${'inProgress' !== statusFilter ? 'bg-gray-800' : 'bg-indigo-800'} text-xs text-white p-2 rounded-full`}>In Progress</button>
                    <button onClick={() => updateStatusFilter('shipped')} className={`${'shipped' !== statusFilter ? 'bg-gray-800' : 'bg-indigo-800'} text-xs text-white p-2 rounded-full`}>Shipped</button>
                </div>

                {features.map(feature => (
                    <div
                        key={feature._id}
                        className={`bg-gray-50 p-4 rounded-lg mb-4 shadow-md 
                        ${feature.status === 'inProgress' ? 'border-l-2 border-blue-300' :
                                (feature.status === 'shipped' ? 'border-l-2 border-green-400' :
                                    (feature.status === 'planned' ? 'border-l-2 border-orange-400' :
                                        (feature.status === 'cancelled' ? 'border-l-2 border-red-400' : 'border-l-2 border-gray-300')))}`}
                    >
                        <h2 className="font-bold text-xl">{feature.title}</h2>
                        <p className="mb-2 cursor-pointer text-gray-600" onClick={() => toggleFeatureExpansion(feature._id)}>
                            {expandedFeatureId === feature._id ? feature.details : (feature.details.length > 160 ? feature.details.substring(0, 160) + ' ...' : feature.details)}
                        </p>
                        <p className="mb-2 text-lg">
                            <span className={`${feature.type === 'bug' ? 'text-red-700' :
                                (feature.type === 'improvement' ? 'text-green-700' :
                                    'text-blue-600')
                                }`}>
                                {window.friendlyCase(feature.type)}
                            </span>,&nbsp;
                            <span className={`${feature.status === 'inProgress' ? 'text-blue-600' :
                                (feature.status === 'shipped' ? 'text-green-700' :
                                    (feature.status === 'planned' ? 'text-orange-600' :
                                        (feature.status === 'cancelled' ? 'text-red-700' : 'text-gray-500')))
                                }`}>
                                {window.friendlyCase(feature.status)}
                                {feature.status === 'shipped' ? <>&nbsp;🎉🎉🎉</> : null}
                            </span>
                        </p>

                        <div className='text-indigo-800'>
                            {(() => {
                                if (['cancelled', 'shipped'].includes(feature.status)) {
                                    return;
                                }
                                // If no one has requested the feature yet
                                if (feature.queueSize < 1) {
                                    return (
                                        <button className="bg-green-600 px-5 text-white p-2 rounded-full mt-2" onClick={() => requestFeature(feature._id)}>
                                            ☝️ I want this!
                                        </button>
                                    );
                                }

                                let message = '';

                                // If the user is the only one who wants this feature
                                if (feature.isIncluded && feature.queueSize === 1) {
                                    message = "You're first in line!";
                                }
                                // If the user and 1 other person want this feature
                                else if (feature.isIncluded && feature.queueSize === 2) {
                                    message = "You and 1 other person want this.";
                                }
                                // If more than 2 people want this feature and it includes the user
                                else if (feature.isIncluded && feature.queueSize > 2) {
                                    message = `${feature.queueSize} ppl want this, including you!`;
                                }
                                // If the user hasn't requested the feature but others have
                                else {
                                    message = `${feature.queueSize} ${feature.queueSize === 1 ? 'person' : 'ppl'} ${feature.queueSize === 1 ? 'wants' : 'want'} this `;
                                    return (
                                        <>
                                            {message}
                                            <button className="bg-green-600 px-5 text-white p-2 rounded-full mt-2" onClick={() => requestFeature(feature._id)}>
                                                ☝️ Me too!
                                            </button>
                                        </>
                                    );
                                }

                                return message;

                            })()}
                        </div>
                    </div>
                ))}
                <div className='p-10 flex justify-center'>
                    {hasMore && <button onClick={() => setPage(p => p + 1)} className='bg-blue-400 text-white rounded-full shadow-lg p-2 px-6'>Load More</button>}
                    {!hasMore && <button onClick={() => setPage(p => p + 1)} className='bg-gray-300 text-white rounded-full shadow-lg p-2 px-6'>No more results</button>}
                    &nbsp;&nbsp;
                    <button onClick={() => window.location.reload()} className='bg-yellow-500 text-white rounded-full shadow-lg p-2 px-6'>Refresh</button>
                </div>
                <div className='p-10'>
                    &nbsp;
                </div>
                {
                    loading && (
                        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                            <div className="loader"></div>
                        </div>
                    )
                }

            </div>
        </div>
    );
}

export default Roadmap;
