import React, { useEffect, useState } from 'react';
import { post } from '../utils/http';
import { notification } from '../utils/notification';

const ProviderOnboarding = props => {

    useEffect(() => {
        localStorage.removeItem('access_token');
        console.log(props)
    }, []);

    const clearedFormData = {
        serviceDetails: '',
        certifications: '',
        references: '',
        location: '',
    };
    const [formData, setFormData] = useState(clearedFormData);
    const [isRegistered, setIsRegistered] = useState(false);
    const [providerName, setProviderName] = useState('');

    const clearedFormDataVerify = {
        phoneNumber: '',
    };
    const [formDataVerify, setFormDataVerify] = useState(clearedFormDataVerify);
    const [formDataStart, setFormDataStart] = useState(clearedFormDataVerify);
    const [formDataRegister, setFormDataRegister] = useState({});

    const [page, setPage] = useState('start');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let r = await post('/provider/service-requests', formData);
        let success = false;
        try {
            if (r.data?.status === 'submitted') {
                if (isRegistered) {
                    setPage('done');
                } else {
                    setPage('register');
                }
                success = true;
            }
        } catch (e) { }
        if (!success) {
            notification({ type: 'Error', text: "We encountered an error on attempting to submit your request. Please contact our Support team." });
        }
    };

    //////////////

    const handleChangeStart = (e) => {
        setFormDataStart({ ...formDataStart, [e.target.name]: e.target.value });
        setFormDataVerify({ ...formDataVerify, [e.target.name]: e.target.value });
        setFormDataRegister({ ...formDataRegister, [e.target.name]: e.target.value });
    };

    const handleSubmitStart = async (e) => {
        e.preventDefault();
        let r = await post('/auth/legacy/start', formDataStart);
        let success = false;
        try {
            if (r.data?.success) {
                setPage('verify');
                success = true;
            }
        } catch (e) { }
        if (!success) {
            notification({ type: 'Error', text: "We couldn't send you a code. Please contact our Support team." });
        }
    };

    //////////////

    const handleChangeVerify = (e) => {
        setFormDataVerify({ ...formDataVerify, [e.target.name]: e.target.value });
        setFormDataRegister({ ...formDataRegister, [e.target.name]: e.target.value });
    };

    const handleSubmitVerify = async (e) => {
        e.preventDefault();
        let r = await post('/auth/verify/done', formDataVerify);
        let success = false;
        try {
            if (r.data?.success) {
                setPage('application');
                success = true;
                localStorage.setItem('access_token', r.data?.accessToken);
                if (r.data?.firstName) {
                    setProviderName(r.data?.firstName);
                    setIsRegistered(true);
                }
            }
        } catch (e) { }
        if (!success) {
            notification({ type: 'Error', text: "We couldn't verify your phone number. Please contact our Support team." });
        }
    };

    //////////////

    const handleChangeRegister = (e) => {
        setFormDataRegister({ ...formDataRegister, [e.target.name]: e.target.value });
    };

    const handleSubmitRegister = async (e) => {
        e.preventDefault();
        let r = await post('/auth/register', formDataRegister);
        let success = false;
        try {
            if (r.data?.success) {
                setPage('done');
                success = true;
            }
        } catch (e) { }
        if (!success) {
            notification({ type: 'Error', text: "We had a problem registering your account. Please contact our Support team." });
        }
    };

    return (
        <div className='pt-10 bg-[#131313]'>
            <div className='md:pt-10 md:pb-10'>
                <div className="flex justify-center items-center bg-[#131313]">
                    {page === 'start' && <>
                        <form
                            className="bg-white p-6 rounded-lg mx-5 my-10 max-w-md w-full"
                            onSubmit={handleSubmitStart}
                        >
                            <h2 className="mt-5 text-3xl font-bold mb-4">
                                {props.providerType != 'transport' ? <>Offer Your Services</> : <>Transport Services</>}
                            </h2>
                            <div className='mb-6'>
                            {props.providerType != 'transport' ? <>
                                <p>Rentables is launching in Jamaica. It's a booking and rentals platform for service providers (plumbers, electricians, make-up artists, etc) and owners of assets (tools, equipment, party supplies, etc).</p>
                                <p className='mt-5'>Join as a provider & let your clients book your services and rent your assets on Rentables. Get ratings/reviews, build your reputation and attract more clients.</p></> : <>
                                <p>Rentables is launching in Jamaica. It's a booking and rentals platform for service providers (plumbers, electricians, make-up artists, etc) and owners of assets (tools, equipment, party supplies, etc).</p>
                                <p className='mt-5'>If you join as a provider of <strong>transportation</strong> services, you will take requests for transporting clients and providers to and from their destinations. If you have the right vehicle, you could also take on requests to transport rental items, depending on size. You set your own prices.</p></>}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="serviceDetails" className="text-indigo-800 text-lg block mb-2">
                                    Verify your phone number <span className='text-red-500 font-bold'>*</span>
                                </label>
                                <label className="text-gray-800 text-sm block mb-2">
                                    Enter your phone number below. We'll send you a one-time code by SMS.
                                </label>
                                <input
                                    name="phoneNumber"
                                    className="shadow appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
                                    onChange={handleChangeStart}
                                    placeholder=""
                                    required
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    className="bg-green-600 text-white py-3 rounded-full px-5"
                                    type="submit"
                                >
                                    Send Code
                                </button>
                            </div>
                        </form>
                    </>
                    }
                    {page === 'verify' && <>
                        <form
                            className="bg-white p-6 rounded-lg mx-5 max-w-md w-full"
                            onSubmit={handleSubmitVerify}
                        >
                            <h2 className="mt-5 text-3xl font-bold mb-4">Offer Your Services</h2>
                            <div className='mb-6'>
                            {props.providerType != 'transport' ? <>Rentables is a cool booking and rentals platform for service providers and asset owners. Join as a provider & let your clients book your services and rent your assets on Rentables. Get ratings/reviews, build your reputation and attract more clients.</> : <>You're onboarding as a transportation service provider, but you can also offer other services.</>}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="serviceDetails" className="text-indigo-800 text-lg block mb-2">
                                    Enter the code you received
                                </label>
                                <input
                                    name="otp"
                                    className="shadow appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
                                    onChange={handleChangeVerify}
                                    placeholder=""
                                    required
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    className="bg-green-600 text-white py-3 rounded-full px-5"
                                    type="submit"
                                >
                                    Verify
                                </button>
                            </div>
                        </form>
                    </>
                    }
                    {page === 'application' &&
                        <form
                            className="bg-white p-6 rounded-lg max-w-md w-full"
                            onSubmit={handleSubmit}
                        >
                            <h2 className="mt-5 text-3xl font-bold mb-4">Offer Your Services</h2>
                            <div className='mb-6'>
                            {props.providerType != 'transport' ? <>Rentables is a cool booking and rentals platform for service providers and asset owners. Join as a provider & let your clients book your services and rent your assets on Rentables. Get ratings/reviews, build your reputation and attract more clients.</> : <>You're onboarding as a transportation service provider, but you can also offer other services.</>}
                            </div>
                            <div className='mb-6'>
                                {!isRegistered ? <>Start here with your service details and we'll create an account for you and then you can download the app to review.</> : <>Hi <strong>{`${providerName}`.trim()}</strong>, submit details of your services below and then check the results in app.</>}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="details" className="text-indigo-800 text-lg block mb-2">
                                {props.providerType != 'transport' ? <>What services do you offer?</>:<>Describe the type vehicles you have for transportation, and locations you can serve.</>} <span className='text-red-500 font-bold'>*</span>
                                </label>
                                <textarea
                                    name="details"
                                    rows="4"
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
                                    onChange={handleChange}
                                    placeholder={props.providerType != 'transport' ? "Enter as much details as possible, including your price list, times of day you work, and everything your clients need to know." : "Later on you will add photos."}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="credentials" className="text-indigo-800 text-lg block mb-2">
                                    {props.providerType != 'transport' ? "Do you have certifications or licences?" : "Do you have a carriage licence?"}
                                </label>
                                <textarea
                                    name="credentials"
                                    rows="3"
                                    className="shadow text-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleChange}
                                    placeholder={props.providerType != 'transport' ? "Enter the details of any certificates, permits, licences or training you have received. This will boost your clients' confidence." : "That's CC, or 'green plate'"}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="references" className="text-indigo-800 text-lg block mb-2">
                                    Do you have references?
                                </label>
                                <textarea
                                    name="references"
                                    rows="3"
                                    className="shadow text-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleChange}
                                    placeholder="Name at least one person (preferably a client) who could speak about the services you provide."
                                />
                            </div>
                            <h2 className="mt-5 text-xl mb-4">That's it. Submit below.</h2>
                            <div className='mb-6 text-gray-500 text-sm'>
                                Rentables is not a classifieds ads platform{props.providerType != 'transport' ? "" : " nor a taxi booking app"}. We've developed a world class booking experience that you will love, and your clients will love booking you there. We're big on trust, and we therefore must verify that you are an authentic provider of the services you mentioned. Please allow our teammates to contact you with questions where necessary. NB: there is absolutely no requirement to pay a fee to get onboarded.
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    className="bg-indigo-800 text-white py-3 rounded-full px-5"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    }
                    {page === 'register' && <>
                        <form
                            className="bg-white p-6 rounded-lg mx-5 max-w-md w-full"
                            onSubmit={handleSubmitRegister}
                        >
                            <h2 className="mt-5 text-3xl font-bold mb-4">Complete Your Registration</h2>
                            <div className='mb-6'>
                                Let's connect your profile with your phone number.
                            </div>
                            <div className="mb-4">
                                <label htmlFor="firstName" className="text-indigo-800 text-lg block mb-2">
                                    First Name
                                </label>
                                <input
                                    name="firstName"
                                    className="shadow appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
                                    onChange={handleChangeRegister}
                                    placeholder=""
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="lastName" className="text-indigo-800 text-lg block mb-2">
                                    Last Name
                                </label>
                                <input
                                    name="lastName"
                                    className="shadow appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
                                    onChange={handleChangeRegister}
                                    placeholder=""
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="emailAddress" className="text-indigo-800 text-lg block mb-2">
                                    Email Address
                                </label>
                                <input
                                    name="emailAddress"
                                    className="shadow appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
                                    onChange={handleChangeRegister}
                                    placeholder=""
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="companyName" className="text-indigo-900 text-lg block mb-2">
                                    Business Name (optional)
                                </label>
                                <input
                                    name="companyName"
                                    className="shadow appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
                                    onChange={handleChangeRegister}
                                    placeholder=""
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    className="bg-green-600 text-white py-3 rounded-full px-5"
                                    type="submit"
                                >
                                    Register
                                </button>
                            </div>
                        </form>
                    </>
                    }
                    {page === 'done' && <div className="bg-white p-6 rounded-lg max-w-md w-full">
                        <h2 className="mt-5 text-3xl font-bold mb-4">Thank You</h2>
                        <div className='mb-6'>
                            <p className='mb-5'>
                                {!isRegistered ? <>We created an account for you, connected to your phone number.</> : <>Your account was updated.</>}
                            </p>
                            <p className='mb-10'>
                                <a href="/download/android">Download the app</a> and log in, and you will see your service request when you switch from Client to Provider mode. It's the last menu item on the lower right of the screen.
                            </p>
                            <p>
                                <a href="/download/android" className='bg-indigo-800 text-white py-3 rounded-full px-5'>
                                    Visit the Play Store
                                </a>
                            </p>
                        </div>
                    </div>}
                </div>
                <br /><br /><br />
            </div>
        </div>
    );
};

export default ProviderOnboarding;
