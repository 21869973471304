import React, { useEffect, useState, useRef, useCallback } from 'react';

import moment from 'moment';

export const DateText = props => {
    return <>{moment(props.date).format("LLL")}</>
}

export const RelativeDate = props => {
    return <>{moment(props.date).format("LLL")}</>
}

export const CurrencyText = props => {
    let amount = !isNaN(props.amount) ? +props.amount : 0;
    if (!amount) amount = !isNaN(props.value) ? +props.value : 0;
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: props.currency || 'JMD' }).format(amount);
};

export function Input(props) {
    return (
        <>
            <input
                id={props.id}
                name={props.name}
                type={props.type || "text"}
                value={props.value}
                autoComplete={props.autoComplete}
                required={props.required}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={props.onChange}
            />
        </>
    )
}

export function ShortenText(props) {
    let { text, length } = props;
    if (text.length > length) {
        return text.substring(0, length) + '...';
    } else {
        return text;
    }
}