const TermsOfUse = () => {
    return (
        <div className="py-10 px-5 md:py-20 md:px-20">
            <div class="text-gray-900 space-y-6">
                <h1 class="text-3xl font-bold">Terms of Use</h1>

                <p class="text-lg">Welcome to Rentables! By using our mobile application and services, you agree to the following terms and conditions:</p>

                <h2 class="text-2xl font-semibold">Use of the App</h2>

                <p class="text-lg">Rentables provides a platform that connects individuals who seek to rent assets with those who have assets available for rent. Your use of the Rentables app is subject to these terms.</p>

                <h2 class="text-2xl font-semibold">Rental Agreements</h2>

                <p class="text-lg">When a Renter requests to rent an asset listed on Rentables, they enter into a rental agreement with the Asset Owner. This agreement governs the terms of the rental, including asset availability, pricing, repair responsibilities, and late returns.</p>

                <h2 class="text-2xl font-semibold">Service Charges</h2>

                <p class="text-lg"><strong>For Companies/Individuals:</strong> Companies and individual Asset Owners will be charged a service fee of 3% of the total transaction amount for using Rentables as a platform to list their assets.</p>

                <p class="text-lg"><strong>For Clients:</strong> Clients (Renters) may be charged a service fee ranging from 1.5% to 2.5% of the total order amount, which will be displayed and agreed upon during the booking process. This fee is to cover the cost of using the Rentables platform.</p>

                <h2 class="text-2xl font-semibold">Responsibility</h2>

                <p class="text-lg">Rentables acts as an intermediary and is not responsible for the condition of rented assets, repairs, or the punctuality of returns. These aspects are the primary responsibility of the Renter and Asset Owner, as defined in their rental agreement.</p>

                <h2 class="text-2xl font-semibold">Rental Period</h2>

                <p class="text-lg">The duration of the rental is determined by the Renter and Asset Owner within their rental agreement. Any late returns or extension requests should be communicated directly between the parties.</p>

                <h2 class="text-2xl font-semibold">Payments</h2>

                <p class="text-lg">Renters are required to make payments for their rentals through the Rentables app, and these payments are subject to the terms set by the Asset Owner. Rentables may charge a service fee for facilitating the rental process.</p>

                <h2 class="text-2xl font-semibold">Privacy</h2>

                <p class="text-lg">Your personal information is protected as per our Privacy Policy. We take your privacy seriously and ensure that your data is handled securely.</p>

                <h2 class="text-2xl font-semibold">Proper Use</h2>

                <p class="text-lg">You agree to use the Rentables app in accordance with its intended purpose and within the bounds of local laws and regulations.</p>

                <h2 class="text-2xl font-semibold">Liability</h2>

                <p class="text-lg">Rentables is not liable for any disputes, damages, injuries, or accidents arising from the use of the rented assets. Any issues should be addressed between the Renter and Asset Owner, as outlined in their rental agreement.</p>

                <h2 class="text-2xl font-semibold">Cancellations and Refunds</h2>

                <p class="text-lg">Cancellations and refunds are subject to the policies set by the Asset Owner within their rental agreement. Rentables is not responsible for refund processing.</p>

                <h2 class="text-2xl font-semibold">Changes to Terms</h2>

                <p class="text-lg">Rentables reserves the right to modify these terms at any time. Any changes will be effective upon posting on our app.</p>

                <h2 class="text-2xl font-semibold">Governing Law</h2>

                <p class="text-lg">Any disputes arising from the use of Rentables services shall be governed by the laws of Jamaica, and the exclusive jurisdiction of the Jamaican courts to settle any dispute arising under these Terms or from your use of the Services.</p>

                <p class="text-lg">By using the Rentables app, you acknowledge that you have read, understood, and agree to these terms. If you disagree with these terms, please do not use our services.</p>

                <p class="text-lg">For inquiries or concerns related to the app, please contact us at <a href="mailto:team+rentables@c5market.com" class="text-blue-600 underline">team+rentables@c5market.com</a>.</p>

                <p class="text-lg italic">Last updated: [Date]</p>
            </div>

        </div>
    )
};

export default TermsOfUse;