import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NotFound from '../Errors/NotFound';

const ProviderProfile = () => {
    const { providerHandle } = useParams();
    const [profile, setProfile] = useState({ reviews: [], offerings: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchProfile();
    }, [providerHandle]);

    const fetchProfile = async () => {
        setLoading(true);
        const url = `${window.getServiceEndpoint('')}/public/profile/handle/${providerHandle}`;
        // const url = `https://core-api.rentables.app/api/v2/public/profile/handle/${providerHandle}`;
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                setProfile(data);
                setLoading(false);
            })
            .catch((err) => {
                setError('Failed to load profile');
                setLoading(false);
            });
    };

    if (loading) return <div className="text-center pt-24">Loading...</div>;
    if (error) return <div className="text-center text-red-500 pt-24">{error}</div>;
    if (!profile.displayName) return <NotFound />;

    return (
        <div className='bg-gray-50'>
            <div className="md:flex w-6/6 md:w-5/6 mx-auto p-4 pt-24">
                <div className="md:bg-white mb-5 rounded-lg overflow-hidden md:w-1/3 md:p-5">
                    <div className="text-center border-b border-gray-200 pb-5">
                        <img src={profile.profileImageUrl} alt={profile.displayName} className="w-24 h-24 mx-auto rounded-full" />
                        <h1 className="mt-2 text-xl md:text-2xl font-bold">{profile.displayName}</h1>
                        <div className="mt-2">
                            <StarRating rating={profile.rating} />
                            <p className="text-gray-600 text-xs mt-2">{profile.reviews?.length} reviews</p>
                        </div>
                    </div>
                    <div className="p-4">
                        <p className="text-gray-600">{profile.bio}</p>
                    </div>
                    <VerificationSection verificationScoreData={profile.verificationScoreData} />
                    <ReviewsSection reviews={profile.reviews} />
                </div>
                <div className='md:w-2/3 md:p-5'>
                    <OfferingsSection offerings={profile.offerings} />
                </div>
            </div>
        </div>
    );

};

const StarRating = ({ rating }) => {
    return (
        <div className="flex justify-center">
            {[...Array(5)].map((_, index) => {
                return (
                    <svg
                        key={index}
                        xmlns="http://www.w3.org/2000/svg"
                        className={`h-5 w-5 ${index < rating ? 'text-yellow-400' : 'text-gray-300'}`}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path d="M9.049 1.868a1 1 0 0 1 1.902 0l1.367 4.21a1 1 0 0 0 .95.692h4.4a1 1 0 0 1 .592 1.807l-3.347 2.456a1 1 0 0 0-.368.958l1.24 4.293a1 1 0 0 1-1.518 1.1L10 14.348a1 1 0 0 0-1.168 0l-3.637 2.678a1 1 0 0 1-1.518-1.1l1.24-4.293a1 1 0 0 0-.368-.958l-3.347-2.456a1 1 0 0 1 .592-1.807h4.4a1 1 0 0 0 .95-.692l1.367-4.21z" />
                    </svg>
                );
            })}
        </div>
    );
};

const VerificationSection = ({ verificationScoreData }) => {
    return (
        <div className="mt-4 p-4 bg-white shadow">
            <h2 className="text-lg font-semibold mb-2">Verification Status</h2>
            {verificationScoreData?.map((data, index) => (
                <div key={index} className="flex items-center my-1">
                    <span className={`h-4 w-4 mr-2 ${data.status === 'verified' ? 'bg-green-500' : 'bg-red-500'} rounded-full`} />
                    <span className="text-md">{data.parameter} was {data.status === 'verified' ? '' : 'not '}verified</span>
                </div>
            ))}
        </div>
    );
};

const OfferingsSection = ({ offerings }) => {
    return (
        <div className="mb-4">
            <h2 className="text-center md:text-left text-xl font-semibold mb-5">{offerings?.length} {offerings?.length !== 1 ? "rentables" : "rentable"}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                {offerings?.map((offering, index) => (
                    <OfferingCard key={index} offering={offering} />
                ))}
            </div>
        </div>
    );
};

const getRentableUrl = (rentable) => {
    let url = `/${rentable.type}s/${rentable[rentable.type === 'asset' ? 'assetNumber' : 'serviceNumber']}`;
    return url;
};

const OfferingCard = ({ offering }) => {
    return (
        <div className="bg-white shadow rounded-lg overflow-hidden cursor-pointer" onClick={() => window.location.href = getRentableUrl(offering)}>
            <img
                src={offering.thumbnailLarge || offering.imageUrl}
                alt={offering.title}
                className="w-full h-56 object-cover"
            />
            <div className="p-5">
                <h3 className="text-md font-semibold"><a href={getRentableUrl(offering)}>{offering.title}</a></h3>
                <p className="text-sm text-gray-500">{offering.category.main} / {offering.category.sub}</p>
                <div className="flex items-center mt-2">
                    <StarRating rating={offering.rating} />
                    <p className="text-sm text-gray-600 ml-2">{offering.numberOfReviews} reviews</p>
                </div>
            </div>
        </div>
    );
};


const ReviewsSection = ({ reviews }) => {
    return (
        <div className="mt-4 p-4 bg-white shadow">
            <h2 className="text-lg font-semibold mb-2">Reviews</h2>
            {reviews?.length > 0 ? (
                reviews.map((review, index) => <ReviewCard key={index} review={review} />)
            ) : (
                <p className="text-md text-gray-500">There aren't any published reviews for this provider as yet.</p>
            )}
        </div>
    );
};

const ReviewCard = ({ review }) => {
    return (
        <div className="border-b border-gray-200 last:border-b-0 py-2">
            <h3 className="text-md font-semibold">{review.author}</h3>
            <StarDisplay value={review.rating} />
        </div>
    );
};

const StarDisplay = ({ value }) => {
    const wholeStars = Math.floor(value);
    const halfStar = (value - wholeStars) >= 0.5;
    const maxStars = 5;

    return (
        <div className="flex">
            {[...Array(wholeStars)].map((_, i) => (
                <span key={i} className="text-yellow-400 text-xl">★</span>
            ))}
            {halfStar && <span className="text-yellow-400 text-xl">☆</span>}
            {[...Array(maxStars - wholeStars - (halfStar ? 1 : 0))].map((_, i) => (
                <span key={i} className="text-gray-300 text-xl">☆</span>
            ))}
        </div>
    );
};

export default ProviderProfile;
