
const setPageProps = ({ title, meta }) => {
  // Set document title
  if (title) {
    document.title = title;
  }

  // Create and append meta tags
  const metaTags = [];
  if (meta) {
    Object.keys(meta).forEach((key) => {
      const el = document.createElement('meta');
      el.name = key;
      el.content = meta[key];
      document.head.appendChild(el);
      metaTags.push(el);
    });
  }

  // Cleanup: Remove appended meta tags
  return () => {
    metaTags.forEach((el) => {
      document.head.removeChild(el);
    });
  };
};

export default setPageProps;
