import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements } from '@stripe/react-stripe-js';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import Translate from "../utils/translate";
import { CurrencyText } from "../utils/templates";

const CheckoutForm = ({ token }) => {
    const [secret, setSecret] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    let serviceEndpointHost = window.getServiceEndpoint('');
    let urlStartPayment = `${serviceEndpointHost}/guest/checkout/start`;
    const [paymentIntentAmount, setPaymentIntentAmount] = useState(0);
    const [paymentTransactionSecret, setPaymentTransactionSecret] = useState('');
    const [billingDetails, setBillingDetails] = useState({});
    const [charges, setCharges] = useState({});
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetch(urlStartPayment, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token }),
        })
            .then(res => res.json())
            .then(data => {
                if ( !data.clientSecret ) {
                    window.location.assign('/transactions/exit?status=charge-failed&headless=true');
                }
                setSecret(data.clientSecret);
                setPaymentIntentAmount(data.paymentIntentAmount);
                setPaymentTransactionSecret(data.transactionId);
                setBillingDetails(data.billingAddress);
                setCharges(data.charges);
            });
    }, [token]);

    const handleSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();
    
        const cardNumberElement = elements.getElement(CardNumberElement);
        
        const result = await stripe.confirmCardPayment(secret, {
            payment_method: {
                type: 'card',
                card: cardNumberElement,
                billing_details: billingDetails
            }
        });
    
        if (result.error) {
            setMessage(result.error.message);
            try {
                window.PaymentScreenResultStatus.postMessage('payment_failed');
                window.PaymentScreenResultResponse.postMessage(result.error.message);
            } catch (e) {}
        } else {
            if (result.paymentIntent.status === 'requires_capture') {
                try {
                    window.PaymentScreenResultStatus.postMessage('payment_successful');
                } catch(e) {}
                setTimeout(() => {
                    let redirecUrl = window.getServiceEndpoint('') + '/guest/checkout/complete?state=' + paymentTransactionSecret;
                    console.log(redirecUrl);
                    window.location.assign(redirecUrl);
                }, 1500);
            }
        }
        setIsLoading(false);
    };    

    return (
        <div className="w-5/6 mx-auto">
            <h3 className='text-2xl mb-5'>Order Summary</h3>
            <div className='grid grid-cols-2 gap-4'>
                <div><span className='font-bold'>Rental Fees</span></div>
                <div><span><CurrencyText value={charges.rental} /></span></div>
                <div><span className='font-bold'>Delivery</span></div>
                <div><span><CurrencyText value={charges.delivery} /></span></div>
                <div><span className='font-bold'>Return</span></div>
                <div><span><CurrencyText value={charges.return} /></span></div>
            </div>
            <form id="payment-form" onSubmit={handleSubmit} className="space-y-4 mt-5">
                <div className="text-lg font-semibold">
                    <strong><Translate text={"Amount to pay"} />: <CurrencyText value={paymentIntentAmount} /></strong>
                </div>
                <hr className="border-gray-500" />
                <div className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="col-span-2 md:col-span-3 flex flex-col">
                            <label className="text-sm font-medium text-gray-600 mt-2">
                                Card Number
                            </label>
                            <CardNumberElement className="p-2 border rounded-md mt-2" />
                        </div>
                        <div className="col-span-1 flex flex-col">
                            <label className="text-sm font-medium text-gray-600 mt-2">
                                Expiry Date
                            </label>
                            <CardExpiryElement className="p-2 border rounded-md mt-2" />
                        </div>
                        <div className="col-span-1 flex flex-col ml-5">
                            <label className="text-sm font-medium text-gray-600 mt-2">
                                CVV Code
                            </label>
                            <CardCvcElement className="p-2 border rounded-md mt-2" />
                        </div>
                    </div>
                </div>
                <div className='mt-5 mb-5 bg-green-100 p-5 text-xs'>
                    Stripe handles all your card details securely and safely processes all our payments. We do not store any payment card information.
                </div>
                <button disabled={isLoading || !stripe || !elements} id="submit" className="bg-green-500 text-white p-2 px-5 rounded-md hover:bg-green-800">
                    <span id="button-text">
                        {isLoading ? <>...</> : <>Pay</>}
                    </span>
                </button>
                {message && <div id="payment-message" className="mt-4 text-red-600 font-bold">{message}</div>}
            </form>
        </div>
    );
}

const PaymentFlow = () => {
    const stripePromise = loadStripe(window.getConfig('STRIPE_PUBLIC_KEY'));

    const location = useLocation();
    const queryString = new URLSearchParams(location.search);
    const token = queryString.get('token');

    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm token={token} />
        </Elements>
    );
}

export default PaymentFlow;
