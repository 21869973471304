import React, { useEffect, useState } from 'react';

const PitchDeck = () => {

  useEffect(() => {
    const endpoint = window.getServiceEndpoint('');
    if (!endpoint) {
      setTimeout(window.location.reload(), 1500);
    } else {
      window.location.assign(`${endpoint}/public/pitch-deck/get-link`);
    }
  })

  return (
    <div className="w-full h-screen">
      Please wait...
    </div>
  );
}

export default PitchDeck;
