import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import Translate from "../utils/translate";
import { CurrencyText } from "../utils/templates";

export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const { email } = props;
    const [billingDetails, setBillingDetails] = useState({ name: props.name });
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (!stripe) {
            return;
        }

        // clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
        // if (!clientSecret) {
        //     return;
        // }

        let clientSecret = localStorage.payment_intent_setmp;

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const cardNumberElement = elements.getElement(CardNumberElement);
        const paymentMethodReq = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement,
        });

        let confirmParams = {
            return_url: `${window.getServiceEndpoint('')}/guest/checkout/complete`,
        };

        if (email) confirmParams.receipt_email = email;

        let clientSecret = localStorage.getItem('payment_intent_setmp');
        localStorage.removeItem('payment_intent_setmp');

        let transactionId = localStorage.getItem('payment_intent_trid');
        localStorage.removeItem('payment_intent_trid');

        const paymentResponse = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardNumberElement,
            },
            receipt_email: email,
        });

        let paymentIntentResult = paymentResponse.paymentIntent || {};

        console.log(paymentIntentResult);

        if (paymentResponse.error) {
            setMessage(paymentResponse.error.message);
        } else {
            let redirecUrl = window.getServiceEndpoint('') + '/guest/checkout/complete?state=' + transactionId;
            console.log(redirecUrl);
            window.location.assign(redirecUrl);
        }

        setIsLoading(false);
    };

    return (
        <div className="rounded-lg shadow-md w-full bg-white p-5">
            <form id="payment-form" onSubmit={handleSubmit} className="space-y-4">
                <div className="text-lg font-semibold">
                    <strong><Translate text={"Amount to pay"} />: <CurrencyText value={localStorage.getItem('payment_intent_amount')} /></strong>
                </div>
                <hr className="border-gray-500" />
                <div className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="col-span-2 md:col-span-3 flex flex-col">
                            <label className="text-sm font-medium text-gray-600 mt-2">
                                Card Number
                            </label>
                            <CardNumberElement className="p-2 border rounded-md mt-2" />
                        </div>
                        <div className="col-span-1 flex flex-col">
                            <label className="text-sm font-medium text-gray-600 mt-2">
                                Expiry Date
                            </label>
                            <CardExpiryElement className="p-2 border rounded-md mt-2" />
                        </div>
                        <div className="col-span-1 flex flex-col ml-5">
                            <label className="text-sm font-medium text-gray-600 mt-2">
                                CVV Code
                            </label>
                            <CardCvcElement className="p-2 border rounded-md mt-2" />
                        </div>
                    </div>
                </div>
                <button disabled={isLoading || !stripe || !elements} id="submit" className="bg-green-500 text-white p-2 px-5 rounded-md hover:bg-green-800">
                    <span id="button-text">
                        {isLoading ? <div className="spinner" id="spinner"></div> : "Submit Payment"}
                    </span>
                </button>
                {message && <div id="payment-message" className="mt-4 text-red-600 font-bold">{message}</div>}
            </form>
        </div>
    );
}
