import React, { useEffect, useState } from 'react';

const TransactionResult = () => {

    const query = new URLSearchParams(window.location.search);

    const [status, setStatus] = useState('');
    
    useEffect(() => {
        document.title = 'Transaction Result';
        const meta = document.createElement('meta');
        meta.name = 'robots';
        meta.content = 'noindex';
        document.getElementsByTagName('head')[0].appendChild(meta);

        setStatus(query.get('status'));

        return () => {  // Cleanup function to remove the meta tag when component unmounts
            document.getElementsByTagName('head')[0].removeChild(meta);
        };
    }, []);

    return (
        <div className={"px-10 md:p-20 text-center"}>
            <h1 className={`text-3xl ${status.indexOf('failed') > -1 ? 'text-red-500' : 'text-blue-500'}`}>
                {status.indexOf('failed') > -1 ? '⚠' : '✔ '}&nbsp;{status.indexOf('failed') > -1 ? 'Transaction Failed' : 'Payment Received'}
            </h1>
            <div className='mt-5 md:w-1/2 mx-auto'>
                {status.indexOf('failed') > -1 && (<h2>Something went wrong and the transasction was unsuccessful. No charge was made to your card.</h2>)}
                {status.indexOf('failed') < 0 && (<h2>Thank you! Your payment was received and will now be verified. Once your payment is verified, the transaction will be completed and the asset owner will be in touch with you.</h2>)}
            </div>
        </div>
    )
};

export default TransactionResult;