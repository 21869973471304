import React, { useEffect, useState } from 'react';

const SoftLaunchPromo = () => {

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);

  useEffect(() => {
    function handleResize() {
      setIsLargeScreen(window.innerWidth >= 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const backgroundClass = isLargeScreen ? 'soft-launch-page-bg' : 'soft-launch-page-bg-res';

  return (
    <div className={`pt-20 ${backgroundClass} font-poppins text-poppins`}>
      <div className='mx-5 md:mx-auto w-full md:w-2/3 pb-20'>
        {/* <div className='md:grid md:grid-cols-2 hidden'>
          <div className=''>
            <img src="/images/logo.png" className='promo-logo-250 md:ml-5 mx-auto' />
          </div>
          <div>
            <div className='text-center md:text-right text-white pt-5'>
              <a href="/">Main Website</a>
              &nbsp;&middot;&nbsp;
              <a href="/">Download App</a>
            </div>
          </div>
        </div> */}
        <div className='max-w-4xl mt-5 bg-black rounded-lg bg-opacity-25 shadow-lg overflow-hidden text-white'>
          <div className='md:flex'>
            <div className='md:flex-shrink-0'>

            </div>
            <div className='p-5'>
              <div className='uppercase tracking-wide md:text-4xl text-3xl font-bold uppercase text-indigo-800 md:font-black'><span className='hidden md:inline'>Product</span> Soft Launch</div>
              <div className='text-yellow-400 text-lg md:text-lg font-bold'>
                Dec 24, 2023
              </div>
              <span className='block mt-1 text-5xl leading-tight font-light text-white'>Do you rent?</span>
              <p className='mt-5 text-gray-100 text-3xl font-light'>Give your clients and your business the gift of a smooth rental experience, in the <span className='font-bold'>Rentables</span> app.</p>
              <div className='mt-4'>
                <div className='text-gray-400 text-md text-xl'><span className='text-teal-600'>List at least one asset</span> before the launch and we’ll include your brand in our promotions for the next three months, leading up to our Grand Launch.</div>
                <div className='mt-2 text-sm text-gray-500'>Conditions apply.</div>
              </div>
              <div className='mt-5'>
                <a href="https://play.google.com/store/apps/details?id=app.rentables" className='text-white bg-indigo-800 hover:bg-indigo-500 font-bold py-2 px-10 rounded-full text-lg'>Download App</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SoftLaunchPromo;
