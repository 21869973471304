import React, { useEffect, useState } from 'react';

const Pledge = () => {

    return (
        <div className='pt-10 bg-[#131313]'>
            <div className='md:pt-10 md:pb-10'>
                <div className="flex justify-center items-center bg-[#131313]">
                    <div
                        className="bg-white p-6 rounded-lg mx-5 my-10 md:w-2/3 w-full"
                    >
                        <h2 className="mt-5 text-3xl font-bold mb-4">Our Why</h2>
                        <h2 className="mt-5 text-xl mb-4">Industries and technology are evolving, and our people are becoming more skilled, but we've lacked a democratised space where these skills & services can be accessed. We created the Rentables app, a platform that facilitates positive contribution to our economy, by creating opportunities for those who have assets and services to provide.</h2>
                        <div className='mb-10 mt-10'>
                        <a href="/onboarding/provider"
                            className="bg-green-600 text-white py-3 rounded-full px-5"
                        >
                            Offer Your Services
                        </a>
                        &nbsp;&nbsp;<a href="https://wa.me/18764941728?text=Hi+😊"
                            className="bg-gray-900 text-white py-3 rounded-full px-3"
                        >
                            Contact Us
                        </a>
                        </div>
                        <hr />
                        <div className='mb-6 mt-6'>
                            For a long time, when you needed to use something temporarily, like a piece of equipment, you'd scrub the Internet, then make some calls. Most likely, only people who can afford to advertise or invest in marketing, would show up. If you needed a service such as babysitting for a day or night, without a trusted platform where verified, top-rated babysitters are sitting and waiting, you probably wouldn't bother to look.
                        </div>
                        <div className="mb-6">
                            As an existing market with high growth potential, we thought there must be a technology driven solution to make it better one day. With our passion for driving efficiency, solving problems and bettering people's lives, we decided to just do it, now. In other markets built on ecommerce rails, you can already find what you want, buy and ship. There are also numerous solutions for getting food, and for you to provide spaces and homes temporarily.
                        </div>
                        <div className="mb-6 font-bold">
                            Why can't you book a trusted nanny to sit in while you go to your interview tomorrow, or book an affordable tent now without waiting until business places open up tomorrow morning?
                        </div>
                        <div className='mb-6'>
                            With Rentables, you can. Of course, if you have these to offer, your clients need you here. Many people, perhaps you, have skills, intention to get skills, certificates, good personality and can offer great services, but can't be found. Sometimes even if you're found, your client doesn't know if they can trust you. We created this platform for people like you.
                        </div>
                        <div className='mb-6'>
                            With our appeal to the masses, we made sure that the app is very easy to use, with a healthy balance between simplicity and the possible complex nature of rentals and booking.
                        </div>
                        <div className="mb-6 font-bold">
                            Trust is a huge deal for us.
                        </div>
                        <div className='mb-6'>
                            While we want people to access or deliver services in a convenient way, it usually opens up an avenue for perpetrators to access vulnerable people. As such, we conduct due diligence on our users, both clients and providers, to give you peace of mind.
                        </div>
                        <div className="mb-6 font-bold">
                            Let's talk about you.
                        </div>
                        <div className='mb-6'>
                            If you have an interest in acquiring some assets or providing a service, your next step is to do some research in the market, and go for it! Reach out to us and we'll assist you with your preparation.
                        </div>
                        <div className='mb-6'>
                            I mean, even if you already made your bank, you can't make too many happy customers, so you can still provide something or a service to someone. On the bright side, you could also generate some additional income for your future generation, since you don't need it. Lol.
                        </div>
                        <div className='mb-6 text-xl font-bold'>
                            We will never charge for listings.
                        </div>
                        <div className='mb-6'>
                            We will make all listings now and in the future free and available to everyone. We have robust processes that will filter low quality offerings. Therefore, if everyone has an opportunity to list what they have to offer, at high quality, we exemplify true democracy. Additionally, the real value we bring is in the marketing we invest in, to bring you real clients. As such, you are required to pay only when those clients book you. Fair?
                        </div>
                        
                        <div className='mb-6 text-xl'>
                            We pledge to continue to create opportunities, improve lives and better our nation.
                        </div>
                        <div className='mb-6'>
                            <strong>You may not have money to give to someone, but you can always share opportunities.</strong>
                        </div>
                        <div className='mb-6'>
                            We challenge you to find 10 people in your contact list who can do hairstyles, makeup, plumbing, electrical or technician work, has a car, tool, or piece of equipment that they could rent, and tell them about Rentables. Ask them to tell you when they get their first booking, and share with us via WhatsApp (18764941728). It will be a great feeling, knowing you helped 10 people. <strong>Just don't leave yourself out!</strong>
                        </div>
                    </div>
                </div>
                <br /><br /><br />
            </div>
        </div>
    );
};

export default Pledge;
