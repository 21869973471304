import React, { useEffect, useState, useRef, useCallback } from 'react';

const NotFound = () => {

    useEffect(() => {
        document.title = '404 - Page Not Found';
        const meta = document.createElement('meta');
        meta.name = 'robots';
        meta.content = 'noindex';
        document.getElementsByTagName('head')[0].appendChild(meta);

        return () => {  // Cleanup function to remove the meta tag when component unmounts
            document.getElementsByTagName('head')[0].removeChild(meta);
        };
    }, []);

    return (
        <div className={"p-20 text-center"}>
            <h1 className='text-3xl'>Not Found</h1>
            <h2>Sorry, we could not find the page you're looking for.</h2>
            <div className='text-center mt-10'>
                <a href="/" className='text-blue-600'>Go Home</a>
            </div>
        </div>
    )
};

export default NotFound;