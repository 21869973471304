import { React, useState, useEffect, Fragment } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import AssetDetails from './pages/Assets/AssetDetails';
import ServiceDetails from './pages/Services/ServiceDetails';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import NotFound from './pages/Errors/NotFound';
import TransactionResult from './pages/TransactionResult';
import AssetShortLink from './pages/Assets/AssetShortLink';
import ServiceShortLink from './pages/Services/ServiceShortLink';
import Support from './pages/Support';
import PaymentFlow from './pages/PaymentFlow';
import LandingPage from './pages/LandingPage';
import Roadmap from './pages/Roadmap';
import PrivayPolicy from './pages/Terms/Privacy';
import TermsOfUse from './pages/Terms/TermsOfUse';
import SoftLaunchPromo from './pages/SoftLaunchPromo';
import ServicesLandingPage from './pages/ServicesLandingPage';
import ProviderOnboarding from './pages/ProviderOnboarding';
import Pledge from './pages/Plede';
import PitchDeck from './pages/PitchDeck';
import ProviderProfile from './pages/Profile/ProviderProfile';

function RedirectToPlayStore() {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = 'https://play.google.com/store/apps/details?id=app.rentables';
  }, [navigate]);

  return null;
}

function RedirectToProviderOnboarding() {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = '/onboarding/provider';
  }, [navigate]);

  return null;
}

function RedirectToTransportProviderOnboarding() {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = '/onboarding/transport';
  }, [navigate]);

  return null;
}

const getQueryStringValue = (key) => {
  return new URLSearchParams(window.location.search).get(key);
}

function App() {

  const [navigation, setNavigation] = useState('');
  const [showNotification, setShowNotification] = useState(true);
  const [configError, setConfigError] = useState(false);
  const [isHeadless, setIsHeadless] = useState(true);
  const [isHomeScreen, setIsHomeScreen] = useState(true);

  useEffect(() => {
    setIsHeadless(getQueryStringValue('headless') === "true" || (window.location.pathname.indexOf('/private') === 0));
    setIsHomeScreen((window.location.pathname.split('?')[0]).split('#')[0] === '/');
    window.setConfig();
    if (!localStorage.HOST_CONFIG) {
      if (window.location.href.indexOf('firstRun') >= 0) {
        setConfigError(true);
      } else {
        setTimeout(() => window.location.assign(window.location.href + (window.location.href.indexOf('?') > -1 ? '&' : '?') + 'firstRun=true'), 1000);
      }
    }
    if (localStorage.getItem('access_token') && localStorage.getItem('access_token') !== 'invalid_client') {
      setNavigation('AUTHED');
    }
  }, []);

  const navigationGuest = [
    // { name: 'Register', href: '/auth/register', current: false },
    // { name: 'Log In', href: '/auth/login', current: false },
    // { name: 'Reset Password', href: '/auth/recover', current: false },
    { name: 'Offer Your Services', href: '/onboard', current: false },
    // { name: 'How it Works', href: '/#how_it_works', current: false },
    { name: 'Our Pledge', href: '/pledge', current: false },
    { name: 'FAQs', href: '/#faqs', current: false },
    { name: 'Android', href: 'https://play.google.com/store/apps/details?id=app.rentables', current: false, class: 'nav-link dark_btn' },
    { name: 'iOS', href: 'https://forms.gle/yQjRuCrcyRL7jrBSA', current: false, class: 'nav-link dark_btn' },
  ];

  /*
  <li className="nav-item">
    <a className="nav-link dark_btn" href="https:/play.google.com">Andoid</a>
  </li>
  <li className="nav-item">
    <a className="nav-link dark_btn" href="https://itunes.apple.com">iOS</a>
  </li>
*/

  const navigationAuthUser = [
    // // { name: `Home`, href: '/', current: false, type: 'textLogo'},
    // { name: 'Orders', href: '/orders', current: false },
    // { name: 'Active Contracts', href: '/contracts', current: false },
    // { name: `Owners Lounge`, href: '/payments/statement', current: false },
    // { name: 'Resources', href: '/resources', current: false },
    // { name: 'About', href: '/about', current: false },
  ];

  const userNavigationAuthUser = [
    // { name: 'Account Settings', href: '/settings/account' },
    // { name: 'Change Password', href: '/settings/password' },
    // { name: 'Log Out', href: '/auth/logout' },
  ];

  const userNavigationGuest = [
  ];

  const textLogo = <><img
    className="h-4"
    src={'/images/rentables-logo-only.png'}
    alt="Rentables App - Rent one. Or rent yours."
  /></>;

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  let logoImage = ``;

  if (true) {
    logoImage = '/images/rentables-logo-only.png';
  }

  return (
    <>
      {!(isHeadless || isHomeScreen) && <Disclosure as="nav" className="bg-top-navx shadow fixed bg-white w-full z-100 py-3">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6">
              <div className="flex justify-between">
                <div className="flex items-center-not">
                  <div className="flex-shrink-0">
                    <a href={"/"}><img
                      className="w-125"
                      src={'/images/rentables-logo-only.png'}
                      alt="Rentables App - Rent one. Or Rent Yours."
                    /></a>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-5 flex space-x-4">
                      {(navigation === 'AUTHED' ? navigationAuthUser : navigationGuest).map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-white text-black'
                              : 'text-black hover:bg-blue-300 hover:bg-opacity-75',
                            'px-3 py-2 rounded-md text-sm font-medium',
                            item.class ? item.class : ''
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.type !== 'textLogo' ? item.name : textLogo}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                {false &&
                  <>
                    <div className="hidden md:block">
                      <div className="ml-4 flex md:ml-6">
                        {/* Profile dropdown */}
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex max-w-xs items-center-not rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white">
                              <span className="sr-only">Open user menu</span>
                              <img className="h-8 w-8 rounded-full" src={'/images/user.png'} alt="" />&nbsp;<strong>{localStorage.getItem('userName')}</strong>{localStorage.getItem('accountNumber') && <>&nbsp;(BOX-{localStorage.getItem('accountNumber')})</>}
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {(navigation === 'AUTHED' ? userNavigationAuthUser : userNavigationGuest).map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <a
                                      href={item.href}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700',
                                        item.class ? item.class : ''
                                      )}
                                    >
                                      {item.name}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center-not justify-center rounded-md bg-white p-2 text-black hover:bg-blue-300 hover:bg-opacity-75 hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                  </>}
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                {(navigation === 'AUTHED' ? navigationAuthUser : navigationGuest).map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-white text-black'
                        : 'text-black hover:bg-blue-300 hover:bg-opacity-75',
                      'block px-3 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-white pt-4 pb-3">
                <div className="flex items-center-not px-5">
                  <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src={'/images/user.png'} alt="" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-black">{localStorage.getItem('userName')}</div>
                    <div className="text-sm font-medium text-black">{localStorage.getItem('userEmail')}</div>
                    {localStorage.getItem('accountNumber') && <div className="text-sm font-medium text-black">(RE-{localStorage.getItem('accountNumber')})</div>}
                  </div>{/*
                      <button
                          type="button"
                          className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-black hover:text-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>*/}
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {(navigation === 'AUTHED' ? userNavigationAuthUser : userNavigationGuest).map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-black hover:bg-blue-300 hover:bg-opacity-75"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>}
      <div className={`${isHeadless ? 'py-10' : 'py-0'}`}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LandingPage />} />
            <Route path="/privacy" element={<PrivayPolicy />} />
            <Route path="/terms" element={<TermsOfUse />} />
            <Route path="/private/pitch-deck" element={<PitchDeck />} />
            <Route path="/support" element={<Support />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/soft-launch" element={<SoftLaunchPromo />} />
            <Route path="/services-landing" element={<ServicesLandingPage />} />
            <Route path="/onboarding/provider" element={<ProviderOnboarding />} />
            <Route path="/onboarding/transport" element={<ProviderOnboarding providerType='transport' />} />
            <Route path="/orders/payment-flow" element={<PaymentFlow />} />
            <Route path="/assets/:assetNumber/:assetTitleSlug" element={<AssetDetails />} />
            <Route path="/assets/:assetNumber" element={<AssetDetails />} />
            <Route path="/a/:assetNumber" element={<AssetShortLink />} />
            <Route path="/services/:serviceNumber/:serviceTitleSlug" element={<ServiceDetails />} />
            <Route path="/services/:serviceNumber" element={<ServiceDetails />} />
            <Route path="/s/:serviceNumber" element={<ServiceShortLink />} />
            <Route path="/transactions/exit" element={<TransactionResult />} />
            <Route path="/pledge" element={<Pledge />} />
            <Route path="/download/android" element={<RedirectToPlayStore />} />
            {['onboard', 'join', 'enrol', 'enroll', 'im-a-boss', 'boss', 'imaboss', 'entrepreneur', 'hire-me', 'hireme'].map((url) => {
              return <Route path={`/${url}`} element={<RedirectToProviderOnboarding />} />
            })}
            {['drive', 'transport'].map((url) => {
              return <Route path={`/${url}`} element={<RedirectToTransportProviderOnboarding />} />
            })}
            <Route path="/" element={<LandingPage />} />
            <Route path="/index.html" element={<LandingPage />} />
            {/* <Route path="/" element={<div className='p-10 pt-15 md:p-20 md:w-1/2 md:mx-auto'><h1 className='text-3xl'>We're Still Building!</h1>
            <p className='mt-5'>While the entire platform isn't available to the public yet, once you have a link to a rentable, you will be able to book and pay online to rent it.</p><h1 className='text-xl mt-5'>Do you have an asset to rent?</h1><p className='mt-5'><span className='font-bold'>Rentables</span> is a peer-to-peer platform that allows asset owners to rent their cars, equipment, tools and other assets that are classified as rentables, to you. Actually, anyone can find something to rent, or rent something they own.</p><p className='mt-5 mb-5'><a href="https://forms.gle/dfzV9UUsjfAaKExv9" target="_blank" className='text-blue-400'>I have an asset to rent. Start here!</a></p><hr /><p className='mt-5'>We're rolling out this platform in stages, and each release will come with exciting features and some loyalty giveaways. <strong><a href="https://cdn.forms-content.sg-form.com/d1edfaaf-46c2-11ee-8301-e2bacb82cb66" target="_blank" className='text-blue-600 cursor-pointer'>Join the waiting list!</a></strong></p></div>} /> */}
            <Route path="/:providerHandle" element={<ProviderProfile />} />
            <Route path="*" element={<NotFound />} />
            {/* Other routes here */}
          </Routes>
        </BrowserRouter>
        {window.location.href.indexOf('headless=true') < 0 && <div className={'mt-10 mb-5-10 text-center text-sm'}>
          <hr />
          <p className='mt-5 text-gray-600'>
            &copy; 2024 Rentables App - Rent One. Or Rent Yours.
          </p>
          <p className='text-xs text-gray-400'>
            Version {window.getConfig('RENTABLES_PLATFORM_VERSION')}
          </p>
          <br />
          <br />
          &nbsp;
        </div>}
      </div>
    </>
  );
}

export default App;
