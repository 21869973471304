import axios from 'axios';
import { notification } from './notification.js';
import { useState } from 'react';

// const httpClient = axios.create({
//     baseURL: window.location.host === 'rentables.app' ? process.env.REACT_APP_API_HOST_PROD : (window.location.host === 'beta.rentables.app' ? process.env.REACT_APP_API_HOST_BETA : process.env.REACT_APP_API_HOST_NONPROD  || 'http://localhost:6633/api'),
// });

window.setConfig = async () => {
    let configHost = process.env.REACT_APP_API_CONFIG_HOST;
    const response = await fetch(configHost).then(response => response.json());
    localStorage.setItem('HOST_CONFIG', JSON.stringify(response));
  };
  
  window.getConfig = key => {
    let configData = {};
    let configString = localStorage.getItem('HOST_CONFIG');
    if (configString) configData = JSON.parse(configString);
    configData = { ...configData, ...process.env };
    return configData[key] || configData[`RENTABLES_API_${key}`] || configData[`RENTABLES_API_MVP_${key}`] || configData[`REACT_APP_API_${key}`] || configData[key.replace('REACT_APP_API_', '')] || null;
  };
  
  window.getServiceEndpoint = service => {
    let envQualifier = 'NON_PROD';
    if (window.location.host.indexOf('local') === 0) envQualifier = 'LOCAL';
    else if (window.location.host.indexOf('beta.rentables.app') === 0) envQualifier = 'BETA';
    else if (window.location.host.indexOf('qa.rentables.app') === 0) envQualifier = 'QA';
    else if (window.location.host.indexOf('dev.rentables.app') === 0) envQualifier = 'dev';
    else if (window.location.host.indexOf('rent-uat.c5office.com') === 0) envQualifier = 'UAT';
    else if (window.location.host.indexOf('rentables.app') === 0) envQualifier = 'PROD';
    else if (window.location.host.indexOf('.rentables.app') > 0) envQualifier = 'PROD';
  
    let finalConfigKey = `${(service || 'RENTABLES_API').toUpperCase()}_HOST_${envQualifier}`;
    let endpoint = window.getConfig(finalConfigKey);
    console.log({finalConfigKey, endpoint});
    return endpoint;
  };

const httpClient = axios.create({
    baseURL: window.getServiceEndpoint()  || 'http://localhost:6633/api/v1',
});

// Add a request interceptor
httpClient.interceptors.request.use(
    (config) => {

        config.headers['X-Loader-Status'] = 'active'; // Add a custom header to indicate active loader
        config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`; // Add a custom header to add access token
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
httpClient.interceptors.response.use(
    (response) => {
        // Remove the custom header when the response is received
        const headers = response.config.headers;
        delete headers['X-Loader-Status'];
        return response;
    },
    (error) => {
        // Remove the custom header when the response is received
        const headers = error.config.headers;
        delete headers['X-Loader-Status'];

        if ([401, 403].includes(error?.response?.status)) {
            window.location.assign(`/login?uri=${encodeURIComponent(window.location.href)}`);
        }
        if (error?.response?.data?.notification) notification(error.response.data.notification);

        return Promise.reject(error);
    }
);

export const useHttpLoader = () => {
    const [isLoading, setIsLoading] = useState(false);

    // Add a request interceptor to update the loading state
    httpClient.interceptors.request.use(
        (config) => {
            if (config.headers['X-Loader-Status'] === 'active') {
                setIsLoading(true);
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    // Add a response interceptor to update the loading state
    httpClient.interceptors.response.use(
        (response) => {
            if (response.config.headers['X-Loader-Status'] === 'active') {
                setIsLoading(false);
            }
            return response;
        },
        (error) => {
            if (error.config.headers['X-Loader-Status'] === 'active') {
                setIsLoading(false);
            }
            return Promise.reject(error);
        }
    );

    return isLoading;
};

export const get = async (url) => {
    const response = await httpClient.get(url);
    return response;
};

export const post = async (url, data) => {
    let response;
    try {
        response = await httpClient.post(url, data);
        if (response.data?.notification) notification(response.data.notification);
    } catch (e) {
        if ([401, 403].includes(e?.response?.status)) {
            window.location.assign(`/login?uri=${encodeURIComponent(window.location.href)}`);
        }
        if (e?.response?.data?.notification) notification(e.response.data.notification);
    }
    return response;
};
