import React, { useEffect, useState, useRef, useCallback } from 'react';
import Slider from 'react-slick';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Star, StarHalf, Check, Close } from '@mui/icons-material';
import { get, post } from '../../utils/http';

import { CurrencyText, Input } from '../../utils/templates';
import Translate from '../../utils/translate';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment';

import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement } from "@stripe/react-stripe-js";

import CheckoutForm from "../../components/CheckoutForm";
import "../../styles/stripe.css";
import { useParams } from 'react-router-dom';
import setPageProps from '../../utils/usePageProps';
import functions from '../../utils/functions';
import NotFound from '../Errors/NotFound';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';

const AssetDetails = () => {
    const [asset, setAsset] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [order, setOrder] = useState({
        // deliveryType: 'owner',
        // date: moment().toDate(),
        // time: moment().add(4, 'hour').toDate(),
        // lengthOfTime: 4,
        // period: 'hour',
        // returnType: 'owner',
        customer: {},
        deliveryAddress: {},
        billingAddress: {},
        transportationCost: {
            duration: 0,
            distance: 0
        },
        amPm: '',
        transportOption: '',
    });

    const stripePromise = loadStripe(window.getConfig('STRIPE_PUBLIC_KEY'));
    const [clientSecret, setClientSecret] = useState("");
    const [noSecret, setNoSecret] = useState(false);

    const { assetNumber } = useParams();

    const [notFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Replace with your API endpoint
        const url = `${window.getServiceEndpoint('')}/guest/assets/${assetNumber}/view`;

        setLoading(true);

        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data?.assetNumber == assetNumber) {
                    setAsset(data);
                    let pageProps = {
                        title: `${data.title} - Rentables App`,
                        meta: {
                            description: data.shortDescription,
                            keywords: functions.generateMetaKeywords(data.shortDescription),
                        },
                    };
                    // console.log(pageProps)
                    setPageProps(pageProps);
                } else {
                    document.title = 'Asset Not Found - Rentables';
                    setNotFound(true);
                }
                setLoading(false);
            })
            .catch((err) => {
                document.title = 'Asset Not Found - Rentables';
                console.error(err);
                setNotFound(true);
                setLoading(false);
            });
    }, []);


    const startCardPaymentFlow = async () => {
        let serviceEndpointHost = window.getServiceEndpoint('');
        let url = `${serviceEndpointHost}/guest/checkout/start`;
        fetch(url, {
            method: "POST",
            headers: window.getAxiosConfig()?.headers,
            body: JSON.stringify({ id: "rentables-order", amount: calculateFinalPrice(), ...order, assetNumber }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.clientSecret) {
                    setClientSecret(data.clientSecret);
                    localStorage.setItem('payment_intent_amount', data.paymentIntentAmount);
                    localStorage.setItem('payment_intent_setmp', data.clientSecret);
                    localStorage.setItem('payment_intent_trid', data.transactionId);
                } else {
                    setNoSecret(true);
                }
            });
    };

    const stripeFormAppearance = {
        theme: 'stripe',
    };
    const stripeFormOptions = {
        clientSecret,
        stripeFormAppearance,
    };

    function StarRating({ stars, numberOfReviews }) {
        const fullStars = Math.floor(stars);
        const halfStar = (stars - fullStars) >= 0.5;
        const quarterStar = (stars - fullStars) < 0.5 && (stars - fullStars) >= 0.25;

        return (
            <div className="flex items-center">
                {[...Array(fullStars)].map((_, index) => (
                    <Star key={index} className="text-orange-400" />
                ))}
                {halfStar && <StarHalf className="text-orange-400" />}
                {quarterStar && (
                    <div className="relative w-5 h-5">
                        <Star className="text-gray-300" />
                        <div className="absolute top-0 left-0 overflow-hidden" style={{ width: '25%' }}>
                            <Star className="text-orange-400" />
                        </div>
                    </div>
                )}
                {[...Array(5 - fullStars - (halfStar || quarterStar ? 1 : 0))].map((_, index) => (
                    <Star key={index} className="text-gray-300" />
                ))}
                <span className="ml-2 text-sm">{stars} ({numberOfReviews} reviews)</span>
            </div>
        );
    }

    const Specifications = ({ data }) => {
        const titleCase = (str) => {
            return str
                .toLowerCase()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
                .join(' ');
        };

        const renderValue = (value) => {
            if (Array.isArray(value)) {
                return value.join(', ');
            } else if (typeof value === 'object') {
                return Object.entries(value)
                    .map(([key, val]) => `${val} (${titleCase(key)})`)
                    .join(', ');
            } else if (typeof value === 'boolean') {
                if (value === false || value === "false") {
                    return <><Close />&nbsp;No</>;
                } else {
                    return <><Check />&nbsp;Yes</>
                }
            } else {
                return value.toString();
            }
        };

        return (
            <div className="flex flex-col gap-4 grid grid-cols-1 lg:grid-cols-2">
                {Object.entries(data).map(([key, value], index) => (
                    <div key={index} className="flex flex-col lg:flex-row w-full">
                        <div className="font-bold p-2 w-full lg:w-1/3">
                            {titleCase(key.replace(/([A-Z])/g, ' $1'))}
                        </div>
                        <div className="p-2 w-full lg:w-2/3">{renderValue(value)}</div>
                    </div>
                ))}
            </div>

        );
    };

    // top section
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex, newIndex) => {
            setCurrentSlide(newIndex);
        },
    };

    const next = () => {
        sliderRef.current.slickNext();
    }

    const prev = () => {
        sliderRef.current.slickPrev();
    }
    // top section

    function DescriptionSection({ descriptionBlocks }) {
        return (
            <div className="my-8">
                {descriptionBlocks.map((block, index) => (
                    <ContentBlock key={index} block={block} />
                ))}
            </div>
        );
    }

    function ContentBlock({ block }) {

        let inlineStyles = {};
        if (block.backgroundColor) inlineStyles.backgroundColor = block.backgroundColor;
        if (block.textColor) inlineStyles.color = block.textColor;
        if (block.marginLeft) inlineStyles.marginLeft = block.marginLeft;
        if (block.marginRight) inlineStyles.marginRight = block.marginRight;
        if (block.marginTop) inlineStyles.marginTop = block.marginTop;
        if (block.marginBottom) inlineStyles.marginBottom = block.marginBottom;
        if (block.paddingLeft) inlineStyles.paddingLeft = block.paddingLeft;
        if (block.paddingRight) inlineStyles.paddingRight = block.paddingRight;
        if (block.paddingTop) inlineStyles.paddingTop = block.paddingTop;
        if (block.paddingBottom) inlineStyles.paddingBottom = block.paddingBottom;
        if (block.borderRadius) inlineStyles.borderRadius = block.borderRadius;
        if (block.borderWidth) inlineStyles.borderWidth = block.borderWidth;
        if (block.borderColor) inlineStyles.borderColor = block.borderColor;
        if (block.borderStyle) inlineStyles.borderStyle = block.borderStyle;
        if (block.boxShadow) inlineStyles.boxShadow = block.boxShadow;
        if (block.rounding) inlineStyles.borderRadius = block.rounding;
        if (block.width) inlineStyles.width = block.width;
        if (block.height) inlineStyles.height = block.height;
        if (block.align) inlineStyles.textAlign = block.align;
        if (block.verticalAlign) inlineStyles.verticalAlign = block.verticalAlign;
        if (block.fontWeight) inlineStyles.fontWeight = block.fontWeight;
        if (block.fontStyle) inlineStyles.fontStyle = block.fontStyle;
        if (block.textDecoration) inlineStyles.textDecoration = block.textDecoration;
        if (block.lineHeight) inlineStyles.lineHeight = block.lineHeight;
        if (block.letterSpacing) inlineStyles.letterSpacing = block.letterSpacing;
        if (block.fontSize) inlineStyles.fontSize = block.fontSize;
        if (block.fontFamily) inlineStyles.fontFamily = block.fontFamily;
        if (block.textTransform) inlineStyles.textTransform = block.textTransform;
        if (block.textShadow) inlineStyles.textShadow = block.textShadow;

        const formatText = (text) => {
            return text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
        };

        switch (block.type) {
            case 'left-image-text-imposed':
            case 'right-image-text-imposed':
            case 'left-half-text-half-image':
            case 'right-half-text-half-image':
                return (
                    <div className={`flex flex-col-reverse mt-16 mb-16 md:flex-row ${block.type === 'left-half-text-half-image' ? 'md:flex-row-reverse' : ''}`} style={inlineStyles}>
                        <div
                            className={`w-full md:w-1/2 p-10`}
                            style={{
                                backgroundColor: block.backgroundColor,
                                color: block.textColor,
                                marginTop: '-20px',
                                marginBottom: '-20px',
                            }}
                        >
                            <h3 className={'text-4xl font-bold mb-4'}>{block.heading}</h3>
                            {block.paragraphs.map((paragraph, index) => (
                                <p key={index} className={'text-xl leading-[1.5] mt-2 mb-2'} dangerouslySetInnerHTML={{ __html: formatText(paragraph) }}></p>
                            ))}
                        </div>
                        {block.image && <img
                            src={block.image}
                            alt=""
                            className={`w-full md:w-1/2`}
                        />}
                    </div>
                );
            case 'lead-text':
                return (<h1 className={`text-5xl mb-10 mt-10  ${block.align === 'center' ? 'text-center' : ''}`} style={inlineStyles} dangerouslySetInnerHTML={{ __html: formatText(block.text) }}></h1>);
            case 'line':
                return (<hr />);
            case 'space':
                return (<div className={'mb-10 mt-10'}>&nbsp;</div>);
            case 'blockquote':
                return (<div className={'mb-5 md:mb-10 mt-5 md:mt-10 bg-gray-100 rounded-lg ml-5 md:ml-10'} style={inlineStyles}>
                    <blockquote className="p-5 md:p-10 px-10 md:px-20 text-center border-l-8 border-gray-300 text-gray-700 text-2xl" dangerouslySetInnerHTML={{ __html: formatText(block.text) }}>
                    </blockquote>
                </div>
                );
            default:
                return (<>
                    <div className={'mb-10 mt-10 p-5'} style={inlineStyles}>
                        {block.heading ? <h3 className={`text-4xl font-bold mb-4 ${block.align === 'center' ? 'text-center' : ''}`} dangerouslySetInnerHTML={{ __html: formatText(block.heading) }}></h3> : ''}
                        {block.paragraphs.map((paragraph, index) => (
                            <p key={index} className={`text-xl leading-[1.5] ${block.align === 'center' ? 'text-center' : ''} mt-3 mb-3`} dangerouslySetInnerHTML={{ __html: formatText(paragraph) }}></p>
                        ))}
                    </div>
                </>
                );
        }


    }

    // rental form

    const getOptionLabel = (period) => {
        return (order.lengthOfTime > 1 || order.lengthOfTime === 0) ? `${period}s` : period;
    };
    // rental form

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setCurrentPage(0);
        setModalOpen(false);
    };

    const startOrder = args => {
        let { period = order?.period, lengthOfTime = order?.lengthOfTime } = args || {};
        setOrder(({ period, lengthOfTime }));
        openModal();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const keys = name.split('.');

        setOrder((prevOrder) => {
            if (keys.length === 1) {
                return { ...prevOrder, [keys[0]]: value };
            } else if (keys.length === 2) {
                return { ...prevOrder, [keys[0]]: { ...prevOrder[keys[0]], [keys[1]]: value } };
            }
        });
    };

    const [r, setR] = useState({});
    const handleChangeR = e => setR({ ...r, [e.target.name]: e.target.value });


    const calculateFinalReturnDate = () => {
        if (!order.date) return "N/A";
        let startDate = moment(order.date + ' ' + order.time);
        let lengthOfTime = parseInt(order.lengthOfTime, 10);

        switch (order.period) {
            case 'hour':
                startDate = startDate.add(lengthOfTime, 'hours');
                break;
            case 'day':
                startDate = startDate.add(lengthOfTime, 'days');
                break;
            case 'week':
                startDate = startDate.add(lengthOfTime, 'weeks');
                break;
            default:
                break;
        }

        let endDate = startDate.format('YYYY-MM-DD [at] hh:mm A');

        return endDate;

    };

    const calculateFinalPrice = () => {
        let finalPrice = 0;
        let discountTable;

        let hourlyRate, dailyRate, weeklyRate, monthlyRate;

        switch (asset.basePrice.period) {
            case 'hour':
                hourlyRate = asset.basePrice.amount;
                dailyRate = asset.rates?.daily || hourlyRate * 24;
                weeklyRate = asset.rates?.weekly || dailyRate * 7;
                monthlyRate = asset.rates?.monthly || weeklyRate * 4;
                break;
            case 'day':
                dailyRate = asset.basePrice.amount;
                hourlyRate = asset.rates?.hourly || dailyRate / 24;
                weeklyRate = asset.rates?.weekly || dailyRate * 7;
                monthlyRate = asset.rates?.monthly || weeklyRate * 4;
                break;
            case 'week':
                weeklyRate = asset.basePrice.amount;
                dailyRate = asset.rates?.daily || weeklyRate / 7;
                hourlyRate = asset.rates?.hourly || dailyRate / 24;
                monthlyRate = asset.rates?.monthly || weeklyRate * 4;
                break;
            default:
                break;
        }

        switch (order.period) {
            case 'hour':
                finalPrice = order.lengthOfTime * hourlyRate;
                discountTable = asset.discounts?.hourly;
                break;
            case 'day':
                finalPrice = order.lengthOfTime * dailyRate;
                discountTable = asset.discounts?.daily;
                break;
            case 'week':
                finalPrice = order.lengthOfTime * weeklyRate;
                discountTable = asset.discounts?.weekly;
                break;
            case 'month':
                finalPrice = order.lengthOfTime * monthlyRate;
                discountTable = asset.discounts?.monthly;
                break;
            default:
                break;
        }

        if (discountTable) {
            let maxDiscountRate = 0;
            for (const discount of discountTable) {
                if (order.lengthOfTime >= discount.qualifier && discount.discountRate > maxDiscountRate) {
                    maxDiscountRate = discount.discountRate;
                }
            }
            finalPrice -= (finalPrice * maxDiscountRate) / 100;
        }

        return finalPrice;
    };

    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleImageClick = () => {
        setIsFullScreen(!isFullScreen);
    };

    const [calculatingTransportationCosts, setCalculatingTransportationCosts] = useState(false);
    const [deliveryChargesCalculated, setDeliveryChargesCalculated] = useState(false);
    const calculateTransportationCosts = async () => {
        setCalculatingTransportationCosts(true);
        const url = `${window.getServiceEndpoint('')}/guest/assets/${assetNumber}/estimate-transit`;
        const payload = {
            destination: order?.deliveryAddress,
            scheduledTime: { date: order?.date, time: order?.time, am: order.amPm },
            transportOption: order?.transportOption,
        };

        const result = await post(url, payload);
        if (result.data) {
            setOrder({ ...order, deliveryCost: result.data.deliveryCost, returnCost: result.data.returnCost, transportationCost: { distance: result.data.distance, duration: result.data.duration } });
        }
        setCalculatingTransportationCosts(false);
        setDeliveryChargesCalculated(true);
    };

    const [showContactDetails, setShowContactDetails] = useState(false);

    const isMobile = (() => {
        const ua = navigator.userAgent;
        const isMobileByAgent = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(ua);
        const isMobileByMaxWidth = window.matchMedia('only screen and (max-width: 760px)').matches;
        return isMobileByAgent || isMobileByMaxWidth;
    })();

    const transportOptions = [
        { label: "Owner delivers and picks up after", value: "owner_to_owner_from" },
        { label: "Owner delivers and I return it after", value: "owner_to_client_from" },
        { label: "I collect and return it after", value: "client_to_client_from" },
        { label: "I collect and owner picks up after", value: "client_to_owner_from" },
    ];

    const [showVerificationModal, setShowVerificationModal] = useState(false);

    const showScoringDetails = () => setShowVerificationModal(true);

    if (loading) return <div className='p-20 text-center'><h1 className='text-2xl text-blue-600 font-bold'>Loading</h1><p className='mt-5'>We're fetching details of this rentable. Please wait a few moments.</p></div>;

    return (
        <div className='font-poppins text-poppins'>
            {showVerificationModal && (
                <>
                    {asset && <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" style={{ zIndex: 500 }} onClick={() => setShowVerificationModal(false)}>
                        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                            <div className="mt-3">
                                <div className="mx-3">
                                    <h3 className="text-xl leading-6 font-medium text-gray-900">
                                        Verification Score: {asset.scoreRating}
                                    </h3>
                                    <div className="my-2 text-sm text-teal-800">
                                        Classification: {asset.scoreClassification || 'UNVERIFIED'}
                                    </div>
                                    <p className="text-sm text-gray-900">
                                        At Rentables, trust is everything. Our team conducts due diligence to verify asset owners and their offerings. Absence of a verified status either means that no attempt was made yet to verify, or that the verification failed.
                                    </p>
                                </div>
                                <div className="mt-2">
                                    {asset.scoreData.map((data, index) => (
                                        <div key={index} className="mt-2">
                                            <div className="flex items-center">
                                                <div className={`mb-5 flex-shrink-0 ${data.status === 'verified' ? 'text-green-600' : 'text-red-500'}`}>
                                                    <i className={`fas ${data.status === 'verified' ? 'fa-check-circle' : 'fa-times-circle'}`}></i>
                                                </div>
                                                <div className="ml-3 mt-2">
                                                    <p className="text-lg font-medium text-gray-900">
                                                        {data.score} - {data.parameter}
                                                    </p>
                                                    <p className="text-sm text-gray-500">{data.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>}
                </>
            )}

            {notFound ? <>
                <div className='p-20 text-center mx-auto w-3/4 md:w-1/2'><h1 className='text-2xl text-red-700 font-bold'>Rentable Not Found</h1><p className='mt-5'>We're having trouble loading the rentable you're looking for. It may have been temporarily deactivated or deleted by the owner.</p><p className='mt-5'><strong>Rentables</strong> is a platform that connects asset owners and people who are seeking rentable items, such as equipment, tools, cars, machines and countless others.</p></div>
            </> : <>
                {isModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-500">
                        <div className="bg-white p-10 rounded-lg max-h-full w-5/6 md:w-1/2 overflow-auto my-10" style={{ maxHeight: 'calc(100vh - 20px)' }}>
                            <div>
                                <button onClick={() => closeModal()} className={'pull-right float-right'}><Close /></button>
                                {currentPage === 0 && (
                                    <div className="">
                                        <h1 className='textxl md:text-3xl font-bold'>Download App</h1>
                                        <h3 className='text-md md:text-xl mt-5 mb-5 text-teal-900'>Renting isn't yet available on this web version.</h3>
                                        <p className='mt-5 mb-5'>
                                            <a href='/download/android' className='rounded-full bg-black text-white font-bold px-5 py-2'>
                                                Download Android App
                                            </a>
                                        </p>

                                        <p className='mt-10 mb-5 text-sm text-gray-700'>
                                            For now, you will need to download the mobile app to rent this item. We are still working on a feature to facilitate renting on the web, but we thought you could still grab the opportunity via mobile, in the meantime. Plus, it's safer to do it on the mobile anyway <span>😉</span>.
                                        </p>
                                    </div>
                                )}
                                {currentPage === -1 && (
                                    <div className="">
                                        <h1 className='text-3xl font-bold'>Start Your Order</h1>
                                        <h3 className='text-xl mt-5 mb-5 font-bold text-indigo-900'>When do you want to rent this item?</h3>

                                        <div className="leading-relaxed md:flex mt-5">
                                            <div className={'mt-2 md:mt-0 mr-5'}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        className="bg-white border-gray-300 font-bold border-b px-5 py-2 outline-none text-center"
                                                        value={order.date}
                                                        onChange={(newValue) => setOrder(prev => ({ ...prev, date: newValue }))}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>

                                        {order?.date &&
                                            <>
                                                <h3 className='text-xl mt-5 mb-5 font-bold text-indigo-900'>At what time?</h3>
                                                <div className="leading-relaxed md:flex mt-5">
                                                    <div className={'mt-2 md:mt-0 mr-5'}>
                                                        <select name="time" onChange={handleInputChange} className="bg-white border-gray-300 font-bold border-b px-5 py-2 outline-none text-center" value={order.time}>
                                                            <option value=""></option>
                                                            {[...Array(12).keys()].map(i => (
                                                                <option key={i} value={i + 1}>{i + 1}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className={'mt-2 md:mt-0 mr-5 flex space-x-2'}>
                                                        <div onClick={() => setOrder(prev => ({ ...prev, amPm: 'am' }))} className={`px-3 py-1 ${order.amPm === 'am' ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-black'} rounded-full cursor-pointer`}>AM</div>
                                                        <div onClick={() => setOrder(prev => ({ ...prev, amPm: 'pm' }))} className={`px-3 py-1 ${order.amPm === 'pm' ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-black'} rounded-full cursor-pointer`}>PM</div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {order?.date && order?.time && order?.amPm &&
                                            <>
                                                <h3 className='text-xl mt-5 mb-5 font-bold text-indigo-900'>For how long?</h3>
                                                <div className="leading-relaxed md:flex mt-5">
                                                    <div className={'mt-2 md:mt-0 mr-5 flex space-x-2'}>
                                                        <input type="number" name="lengthOfTime" value={order.lengthOfTime} onChange={handleInputChange} min="1" className="bg-white border-gray-300 font-bold border-b px-5 py-2 outline-none text-center w-20" />
                                                        <select name="period" value={order.period} onChange={handleInputChange} className="bg-white border-gray-300 font-bold border-b px-5 py-2 outline-none text-center">
                                                            <option>select period</option>
                                                            <option value="hour">hour{order.lengthOfTime != 1 ? 's' : ''}</option>
                                                            <option value="day">day{order.lengthOfTime != 1 ? 's' : ''}</option>
                                                            <option value="week">week{order.lengthOfTime != 1 ? 's' : ''}</option>
                                                            <option value="month">month{order.lengthOfTime != 1 ? 's' : ''}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {order?.date && order?.time && order?.amPm && order?.lengthOfTime && order?.period &&
                                            <>
                                                <h3 className='text-xl mt-5 mb-5 font-bold text-indigo-900'>Who transports the item?</h3>
                                                <div className="leading-relaxed">
                                                    {transportOptions.map((option, index) => (
                                                        <div key={index} onClick={() => setOrder(prev => ({ ...prev, transportOption: option.value }))} className={`px-3 py-1 m-1 ${order.transportOption === option.value ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-black'} rounded-full cursor-pointer`}>{option.label}</div>
                                                    ))}
                                                </div>
                                            </>
                                        }

                                        {order?.date && order?.time && order?.amPm && order?.transportOption && order?.lengthOfTime && order?.period && order.transportOption &&
                                            <>
                                                <button onClick={() => { if (order.transportOption.includes('owner')) { setCurrentPage(1) } else { setCurrentPage(2) } }} className="bg-blue-500 text-white px-4 py-2 col-span-full mt-5 rounded-lg">Continue</button>
                                            </>
                                        }

                                    </div>
                                )}
                                {currentPage === 1 && (
                                    <div className="text-xl leading-6">
                                        <h1 className={'text-3xl font-bold'}>Delivery and Return</h1>
                                        {order?.transportOption?.includes('owner') &&
                                            <div className={'mt-5 grid grid-cols-1 md:grid-cols-2 gap-4'}>
                                                <h3 className="text-xl font-medium mb-4 col-span-full text-indigo-600">Address</h3>
                                                <div className="mb-4 col-span-full">
                                                    <label className="block text-sm font-bold mb-2">Country</label>
                                                    <select name="deliveryAddress.country" value={order?.deliveryAddress?.country} onChange={handleInputChange} className="bg-blue-50 border-b border-gray-200 px-5 py-2 w-full outline-none">
                                                        <option value="Jamaica">Jamaica</option>
                                                    </select>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="block text-sm font-bold mb-2">Address Line 1</label>
                                                    <input type="text" name="deliveryAddress.line1" value={order?.deliveryAddress?.line1} onChange={handleInputChange} className="bg-blue-50 border-b border-gray-200 px-5 py-2 w-full outline-none" />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="block text-sm font-bold mb-2">Address Line 2</label>
                                                    <input type="text" name="deliveryAddress.line2" value={order?.deliveryAddress?.line2} onChange={handleInputChange} className="bg-blue-50 border-b border-gray-200 px-5 py-2 w-full outline-none" />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="block text-sm font-bold mb-2">City/Town</label>
                                                    <input type="text" name="deliveryAddress.city" value={order?.deliveryAddress?.city} onChange={handleInputChange} className="bg-blue-50 border-b border-gray-200 px-5 py-2 w-full outline-none" />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="block text-sm font-bold mb-2">Parish/State/Province</label>
                                                    <select name="deliveryAddress.state" value={order?.deliveryAddress?.state} onChange={handleInputChange} className="bg-blue-50 border-b border-gray-200 px-5 py-2 w-full outline-none">
                                                        <option value="Kingston">Kingston</option>
                                                        <option value="St. Andrew">St. Andrew</option>
                                                        <option value="St. Catherine">St. Catherine</option>
                                                        <option value="Clarendon">Clarendon</option>
                                                        <option value="Manchester">Manchester</option>
                                                        <option value="St. Elizabeth">St. Elizabeth</option>
                                                        <option value="Westmoreland">Westmoreland</option>
                                                        <option value="Hanover">Hanover</option>
                                                        <option value="St. James">St. James</option>
                                                        <option value="Trelawny">Trelawny</option>
                                                        <option value="St. Ann">St. Ann</option>
                                                        <option value="St. Mary">St. Mary</option>
                                                        <option value="Portland">Portland</option>
                                                    </select>

                                                </div>
                                            </div>
                                        }

                                        <div className={'mt-10 mb-5 border-t-2 border-b-2 border-gray-200 p-5 text-center'}>
                                            <h3 className={'text-xl font-bold'}>Delivery Cost</h3>
                                            <div className={'text-2xl font-bold text-red-500'}>
                                                <CurrencyText value={order?.deliveryCost} />
                                            </div>
                                            <h3 className={'text-xl font-bold mt-5'}>Return Cost</h3>
                                            <div className={'text-2xl font-bold text-red-500'}>
                                                <CurrencyText value={order?.returnCost} />
                                            </div>
                                            {order?.transportOption?.includes('owner') && +order?.transportationCost?.distance > 0 ?
                                                <div className='mt-5 text-sm text-gray-600'>
                                                    Estimated {Math.round(+order?.transportationCost?.distance / 1000)} km, {order.transportationCost.duration}
                                                </div>
                                                : <></>}
                                            {order?.transportOption?.includes('owner') ?
                                                <div className={'mt-5'}>
                                                    {!calculatingTransportationCosts && <button onClick={() => { calculateTransportationCosts() }} className="bg-orange-600 text-white px-4 py-2 col-span-full rounded-lg">Calculate</button>}
                                                    {calculatingTransportationCosts && <button className="bg-blue-100 text-white px-4 py-2 col-span-full">Estimating Distance ...</button>}
                                                </div>
                                                :
                                                <div className={'mt-5'}>
                                                    You have selected options to collect and return the asset yourself. There are no delivery or return charges.
                                                </div>
                                            }
                                        </div>

                                        {(deliveryChargesCalculated || order?.deliveryType !== 'owner') &&
                                            <>
                                                <button onClick={() => { startCardPaymentFlow(); setCurrentPage(2) }} className="bg-blue-500 text-white px-4 py-2 col-span-full rounded-lg">Continue</button>

                                            </>
                                        }
                                        &nbsp;&nbsp;
                                        <button onClick={() => setCurrentPage(0)} className="bg-gray-800 text-white px-4 py-2 rounded-lg">Back</button>
                                    </div>
                                )}
                                {currentPage === 2 && (
                                    <div className="text-xl leading-6">
                                        <h1 className={'text-3xl font-bold'}>Your Details</h1>

                                        <div className={'mt-5 grid grid-cols-1 md:grid-cols-2 gap-4'}>
                                            <h3 className="text-xl font-medium mb-4 col-span-full text-indigo-600">Contact Information</h3>
                                            <div className="mb-4">
                                                <label className="block text-sm font-bold mb-2">First Name</label>
                                                <input type="text" name="customer.firstName" value={order?.customer?.firstName} onChange={handleInputChange} className="bg-blue-50 border-b border-gray-200 px-5 py-2 w-full outline-none" />

                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-sm font-bold mb-2">Last Name</label>
                                                <input type="text" name="customer.lastName" value={order?.customer?.lastName} onChange={handleInputChange} className="bg-blue-50 border-b border-gray-200 px-5 py-2 w-full outline-none" />

                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-sm font-bold mb-2">Email Address</label>
                                                <input type="email" name="customer.email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" value={order?.customer?.email} onChange={handleInputChange} className="bg-blue-50 border-b border-gray-200 px-5 py-2 w-full outline-none" />
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-sm font-bold mb-2">Telephone Number</label>
                                                <input type="tel" name="customer.phoneNumber" pattern="1-876-\d{3}-\d{4}" value={order?.customer?.phoneNumber} onChange={handleInputChange} className="bg-blue-50 border-b border-gray-200 px-5 py-2 w-full outline-none" />
                                            </div>
                                        </div>
                                        <div className={'mt-5 grid grid-cols-1 md:grid-cols-2 gap-4'}>
                                            <h3 className="text-xl font-medium mb-4 col-span-full text-indigo-600">Address</h3>
                                            {order?.deliveryAddress?.line1 && <>
                                                {/* Button to copy delivery address if exists  */}
                                                <button onClick={() => setOrder({ ...order, billingAddress: order?.deliveryAddress || {} })} className="bg-gray-600 text-white px-5 py-2 w-full outline-none rounded-lg">Copy Billing Address</button>
                                            </>
                                            }
                                            <div className="mb-4 col-span-full">
                                                <label className="block text-sm font-bold mb-2">Country</label>
                                                <select name="billingAddress.country" value={order?.billingAddress?.country} onChange={handleInputChange} className="bg-blue-50 border-b border-gray-200 px-5 py-2 w-full outline-none">
                                                    <option value="Jamaica">Jamaica</option>
                                                </select>
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-sm font-bold mb-2">Address Line 1</label>
                                                <input type="text" name="billingAddress.line1" value={order?.billingAddress?.line1} onChange={handleInputChange} className="bg-blue-50 border-b border-gray-200 px-5 py-2 w-full outline-none" />
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-sm font-bold mb-2">Address Line 2</label>
                                                <input type="text" name="billingAddress.line2" value={order?.billingAddress?.line2} onChange={handleInputChange} className="bg-blue-50 border-b border-gray-200 px-5 py-2 w-full outline-none" />
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-sm font-bold mb-2">City/Town</label>
                                                <input type="text" name="billingAddress.city" value={order?.billingAddress?.city} onChange={handleInputChange} className="bg-blue-50 border-b border-gray-200 px-5 py-2 w-full outline-none" />
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-sm font-bold mb-2">Parish/State/Province</label>
                                                <select name="billingAddress.state" value={order?.billingAddress?.state} onChange={handleInputChange} className="bg-blue-50 border-b border-gray-200 px-5 py-2 w-full outline-none">
                                                    <option value="Kingston">Kingston</option>
                                                    <option value="St. Andrew">St. Andrew</option>
                                                    <option value="St. Catherine">St. Catherine</option>
                                                    <option value="Clarendon">Clarendon</option>
                                                    <option value="Manchester">Manchester</option>
                                                    <option value="St. Elizabeth">St. Elizabeth</option>
                                                    <option value="Westmoreland">Westmoreland</option>
                                                    <option value="Hanover">Hanover</option>
                                                    <option value="St. James">St. James</option>
                                                    <option value="Trelawny">Trelawny</option>
                                                    <option value="St. Ann">St. Ann</option>
                                                    <option value="St. Mary">St. Mary</option>
                                                    <option value="Portland">Portland</option>
                                                </select>

                                            </div>


                                        </div>

                                        <hr className='mt-5' />
                                        <div className='mt-5'>
                                            <button onClick={() => { startCardPaymentFlow(); setCurrentPage(3) }} className="bg-blue-500 text-white px-4 py-2 rounded-lg">Proceed to Pay</button>
                                            &nbsp;&nbsp;
                                            <button onClick={() => { if (order.transportOption.includes('owner')) { setCurrentPage(1) } else { setCurrentPage(0) } }} className="bg-gray-800 text-white px-4 py-2 rounded-lg">Back</button>
                                        </div>
                                    </div>
                                )}
                                {currentPage === 3 &&
                                    <div className="text-xl leading-6">
                                        <h1 className={'text-3xl font-bold'}>Complete Card Payment</h1>
                                        <div className="mt-5 mb-5 d-flex rounded-xl bg-gray-100 p-5">
                                            <img src="/images/powered-by-stripe.png" style={{ height: "30px" }} alt="Powered by Stripe" />
                                            <br />
                                            <p className={'text-xs text-gray-500'}>
                                                This payment will be held by Rentables until you have received the asset and are satisfied.
                                            </p>
                                            <p className={'text-xs text-gray-500'}>
                                                Once the contract is complete, we will disburse the funds to the asset owner.
                                            </p>
                                            <p className={'text-xs text-gray-500'}>
                                                On your card statement or account history, this transaction will be described by <strong>JM*RENTABLES</strong>.
                                            </p>
                                        </div>

                                        <div className="stripeX mt-5 flex flex-col items-center">
                                            {clientSecret && (
                                                <div className="w-full max-w-screen-lg mx-auto">
                                                    <Elements stripe={stripePromise}>
                                                        <CheckoutForm email={order?.customer?.email} name={order?.customer?.name} amount={() => calculateFinalPrice()} />
                                                    </Elements>
                                                </div>

                                            )}
                                            {noSecret && !clientSecret && (
                                                <div className="bg-orange-200 p-5 text-sm rounded shadow w-full max-w-screen-lg mx-auto">
                                                    <p className="text-lg"><Translate text="Payment Request Failed" /></p>
                                                    <br />
                                                    <p><Translate text="Sorry, we can't accept a debit/credit card payment right now." /></p>
                                                    <br />
                                                    <p><Translate text="Please contact Support via Live Chat." /></p>
                                                </div>
                                            )}
                                            <br />
                                        </div>

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )}

                {asset && <>

                    {/* top section */}
                    <div className="flex flex-col md:flex-row overflow-hidden md:p-10 bg-gray-100">
                        <div className="-full md:w-[55%] border border-gray-300 md:border-0 relative md:bg-black md:py-5 md:px-5 md:pl-0 md:pr-0 mt-0 md:mt-0 bg-white md:bg-gray-100 shadow-sm">
                            {isFullScreen ? (
                                <div className="fixed inset-0 z-50 bg-black bg-opacity-90">
                                    <button
                                        className="absolute top-20 right-10 p-4 text-white z-600"
                                        onClick={handleImageClick}
                                    >
                                        <Close />
                                    </button>
                                    <Slider ref={sliderRef} {...settings} className="absolute inset-0">
                                        {asset.images.map((image, index) => (
                                            <div key={index} className="h-screen w-screen">
                                                <img
                                                    src={image.imageUrl}
                                                    alt=""
                                                    className="h-full mx-auto cursor-pointer"
                                                    onClick={handleImageClick}
                                                />
                                            </div>
                                        ))}
                                    </Slider>
                                    <div className="absolute bottom-0 left-0 right-0 text-center bg-gray-900 p-0 flex justify-center items-center text-white ml-[-10px] bg-opacity-50">
                                        <button onClick={prev} className="bg-transparent p-2 m-2">
                                            <ArrowBackIos />
                                        </button>
                                        <span>
                                            {asset.images.length > 0 ? (
                                                asset.images.length > 1 ? <>{currentSlide + 1} of {asset.images.length} pics</> : 'Only 1 pic is here') : 'No pics here...yet'}
                                        </span>
                                        <button onClick={next} className="bg-transparent p-2 m-2">
                                            <ArrowForwardIos />
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <Slider ref={sliderRef} {...settings} className="ml-[-10px]">
                                    {asset.images.map((image, index) => (
                                        <div key={index} className="h-[400px] md:h-[600px] relative shadow-lg shadow-white border-5 border-white rounded-lg">
                                            <img
                                                src={image.imageUrl}
                                                alt=""
                                                className="h-full w-full object-cover cursor-pointer"
                                                onClick={handleImageClick}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            )}
                            <div className="absolute bottom-0 left-0 right-0 text-center bg-gray-50 p-0 flex justify-center items-center text-gray-800 ml-[-10px] mt-0">
                                <button onClick={prev} className="bg-transparent p-2 m-2">
                                    <ArrowBackIos />
                                </button>
                                <span>{asset.images.length > 0 ? (
                                    asset.images.length > 1 ? <>{currentSlide + 1} of {asset.images.length} pics</> : 'Only 1 pic is here') : 'No pics here...yet'}
                                </span>
                                <button onClick={next} className="bg-transparent p-2 m-2">
                                    <ArrowForwardIos />
                                </button>
                            </div>
                        </div>
                        <div className="w-full md:w-[45%] p-15 md:p-10 md:pt-5 md:pr-20">
                            <div className={'p-5 md:pt-5 md:p-0'}>
                                <h1 className="text-xl md:text-2xl font-bold">{asset.title}</h1>
                                <p className='mt-5 text-gray-400'>
                                    <span className='text-indigo-600'>
                                        {asset.category.main}
                                    </span>&nbsp;/&nbsp;
                                    <span className='text-indigo-600'>
                                        {asset.category.sub}
                                    </span>
                                </p>
                                <p className="text-lg mt-5 mb-2">
                                    Offered by <a href={`/${asset.ownerHandle}`}><strong>{asset.ownerDisplayName}</strong></a> &nbsp;
                                </p>
                                <StarRating stars={asset.rating} numberOfReviews={asset.numberOfReviews} />
                                <p className="text-sm mt-5">{asset.shortDescription}</p>
                                {/*<div className="mt-5 w-full text-center p-5 shadow-md bg-white rounded-lg">
                                    <h2 className="text-xl font-bold"><CurrencyText value={asset.basePrice?.amount} /> per {asset.basePrice?.period}</h2>
                                    <p className="mt-2 mb-2">BASE RATE</p>
                                    <button className="font-bold bg-orange-500 px-5 py-2 text-white cursor-pointer w-full" onClick={() => openModal({})}>Rent this Item</button>
                                    <p className="text-sm mt-2 mb-2">Minimum rental period: {asset.minimumRentalPeriod?.value} {asset.minimumRentalPeriod?.period}</p>
                                </div>*/}
                                <div className="mt-5 w-full p-5 shadow-md bg-white rounded-lg cursor-pointer" onClick={() => showScoringDetails()}>
                                    <h2 className="text-xs">VERIFICATION SCORE</h2>
                                    <p className="mt-2 mb-2">
                                        <span className='text-green-600 font-bold text-lg'>
                                            {asset.scoreRating}
                                        </span>
                                        &nbsp;&nbsp;
                                        <span className='text-teal-700 text-xs'>
                                            {asset.scoreClassification ?? ""}
                                        </span>
                                        &nbsp;&nbsp;
                                        <span className='text-gray-500 text-xs'>
                                            (View Details)
                                        </span>
                                        &nbsp;
                                    </p>
                                </div>
                                <br />
                                <button className="rounded-full font-bold bg-black px-5 py-2 text-white cursor-pointer w-full" onClick={() => openModal({})}>Rent this Item</button>
                            </div>
                        </div>

                    </div>
                    {/* top section */}

                    {/* offers section */}
                    {false &&
                    <div className="text-center my-8 mt-0 bg-gray-200 p-10">
                        <h2 className="text-3xl font-bold mb-4">Quick Picks</h2>
                        <div className="flex justify-center flex-wrap">
                            {asset.offers?.map((offer) => (
                                <div key={`${offer.lengthOfTime}.${offer.period}`} className="bg-gray-100 rounded-lg p-4 m-5 w-60">
                                    <div className="text-xl font-bold">{offer.price}</div>
                                    <div className="text-md">{offer.label}</div>
                                    {/* <button className="bg-blue-500 text-white rounded p-2 mt-4 w-full" onClick={() => startOrder(offer)}>
                                        Take This
                                    </button> */}
                                </div>
                            ))}
                        </div>
                    </div>
                    }
                    {/* offers section */}

                    <h1 className={'text-3xl md:text-5xl mt-10 text-center font-bold'}>
                        Product Details
                    </h1>

                    {/* Specifications */}
                    <div className={'m-5 md:m-20 mt-0 md:mt-0 p-5 pt-0'}>

                        <h1 className={'text-2xl text-center mb-10 mt-10 font-light'}>
                            Specifications
                        </h1>
                        <div className={'bg-blue-50 p-5 rounded-3xl'}>
                            <Specifications data={asset.specs || {}} />
                        </div>

                    </div>
                    {/* Specifications */}

                    <DescriptionSection descriptionBlocks={asset.descriptionBlocks} />

                    {/* rental form */}
                    <div
                        className={'text-center items-center p-5 text-white bg-cyan-800 relative'}
                        style={{
                            backgroundImage:
                                'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'10\' height=\'10\' viewBox=\'0 0 50 50\'%3E%3Cpolygon points=\'25,0 27.5,10 22.5,10\' fill=\'rgba(255,255,255,0.15)\'/%3E%3Cpolygon points=\'25,50 27.5,40 22.5,40\' fill=\'rgba(255,255,255,0.15)\'/%3E%3Cpolygon points=\'0,25 10,27.5 10,22.5\' fill=\'rgba(255,255,255,0.5)\'/%3E%3Cpolygon points=\'50,25 40,27.5 40,22.5\' fill=\'rgba(255,255,255,0.15)\'/%3E%3C/svg%3E")',
                            backgroundRepeat: 'repeat',
                        }}
                    >
                        <h1 className={'text-2xl md:text-4xl m-5 md:mb-10 md:mt-10 font-black'}>
                            Are you ready for this?
                        </h1>
                        {/* <div className={'md:flex md:justify-center items-center'}>
                            <input
                                type="number"
                                name="lengthOfTime"
                                value={order.lengthOfTime}
                                onChange={handleInputChange}
                                placeholder="4"
                                className="w-full md:w-auto px-10 py-5 text-black bg-white text-xl font-bold outline-none"
                            />
                            <select className="mt-5 md:mt-0 px-10 py-5 text-white bg-black text-xl font-bold appearance-none outline-none" name={'period'} value={order.period} onChange={handleInputChange}>
                                <option value="hour">{getOptionLabel('Hour')}</option>
                                <option value="day">{getOptionLabel('Day')}</option>
                                <option value="week">{getOptionLabel('Week')}</option>
                            </select>
                        </div> */}
                        <div className={'text-center pb-5'}>
                            <button className={'bg-black text-white p-5 px-10 text-lg font-bold rounded-full shadow-lg'} onClick={() => startOrder()}>
                                Get the App
                            </button>

                        </div>

                    </div>
                    {/* rental form */}

                </>}
            </>}
        </div>
    );
}

export default AssetDetails;
